import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import GoogleButton from 'react-google-button';
import { getAnalytics, logEvent } from 'firebase/analytics';
import {
  Card, Divider, Typography, useTheme,
} from '@mui/material';
import {
  EmailAuthProvider,
} from 'firebase/auth';
import { useLocation } from 'react-router-dom';
import { useMobileDevice } from '../../Common/responsiveUIHelper';
import './login.css'; // This uses CSS modules.
import StyledFirebaseAuth from './StyledFirebaseAuth/StyledFirebaseAuth';

const analytics = getAnalytics();

const LoginWrapper = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
  align-items: center;
  justify-content: space-evenly;
  flex-direction: column;
  background-color: ${(props) => props.theme.palette.background.default};
`;

const LoginCard = styled(Card)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: ${({ isMobileDevice }) => (isMobileDevice ? 'flex-start' : 'center')};
  padding: 15px;
  max-width: 496px;
  /* height:100%; */
`;

const Login = ({ signInWithGoogle, providedAuth }) => {
  const location = useLocation();
  const theme = useTheme();
  const isMobileDevice = useMobileDevice();
  const uiConfig = {
    signInSuccessUrl: location.state?.from.pathname || '/',
    signInOptions: [
      EmailAuthProvider.PROVIDER_ID,
    ],
    // callbacks: {
    //   signInSuccessWithAuthResult: () => false,
    // },
  };

  // const [loadingContext, setLoadingContext] = useState(true);
  const handleSignInWithGoogle = async () => {
    try {
      logEvent(analytics, 'login');
      signInWithGoogle();
      window.history.replaceState({}, '', location.state?.from.pathname || '/');
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <LoginWrapper theme={theme}>
      {/* <FormWrapper>
                <UserForm title="Sign in"
                handleSignInSubmit={createUserWithEmailAndPassword}>Sign in</UserForm>
              </FormWrapper>
              <FormWrapper>
                <UserForm title="Login" handleSignInSubmit={signInWithEmailAndPassword}>
                Sign in</UserForm>
              </FormWrapper> */}
      <LoginCard isMobileDevice={isMobileDevice}>
        <Typography variant="h4" component="div" style={{ fontWeight: 'bold', minWidth: 400, color: theme.palette.primary.main }}>
          Welcome to Wealthlucid
        </Typography>
        <StyledFirebaseAuth
          style={{ width: '100%' }}
          uiConfig={uiConfig}
          firebaseAuth={providedAuth}
          className="firebaseUi"
        />
        <Typography variant="body1" component="div" style={{ margin: 15 }}>
          or
        </Typography>
        <Divider style={{ width: '80%', marginBottom: 15 }} />
        <GoogleButton onClick={handleSignInWithGoogle}>Sign in with Google</GoogleButton>
      </LoginCard>
    </LoginWrapper>
  );
};

Login.propTypes = {
  signInWithGoogle: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  providedAuth: PropTypes.object.isRequired,
};

export default Login;
