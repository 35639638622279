import React from 'react';
import { alpha, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Avatar from '@mui/material/Avatar';
import Grid from '@mui/material/Grid';
import { DisplaySettings, Schedule, VpnKey } from '@mui/icons-material';

const mock = [
  {
    title: 'Connect your brokerage accounts',
    subtitle:
      'Save time by linking your accounts so that you do not have to log in manually every time you invest',
    icon: <VpnKey />,
  },
  {
    title: 'Set up a recurring investment plan',
    subtitle:
      'The investment can be made when you deposit a specified amount or you can set up a recurring investment schedule',
    icon: <Schedule />,
  },
  {
    title: 'Specify where you would like to invest',
    subtitle:
      'Select any investments that you would make through your broker and have them automatically executed',
    icon: <DisplaySettings />,
  },
];

const Services = () => {
  const theme = useTheme();
  return (
    <Box>
      <Box
        marginBottom={4}
        justifyContent="center"
        data-aos="fade-up"
        data-aos-delay={100}
        data-aos-offset={100}
        data-aos-duration={600}
      >
        <Typography
          gutterBottom
          variant="h4"
          fontWeight={700}
        >
          It only takes a few minutes to get started
        </Typography>
      </Box>
      <Grid container spacing={2}>
        {mock.map((item) => (
          <Grid item xs={12} md={4} key={item.title}>
            <Box
              width={1}
              height={1}
            >
              <Box
                display="flex"
                flexDirection="column"
                alignItems="center"
              >
                <Box
                  component={Avatar}
                  width={60}
                  height={60}
                  marginBottom={2}
                  bgcolor={alpha(theme.palette.primary.main, 0.1)}
                  color={theme.palette.primary.main}
                >
                  {item.icon}
                </Box>
                <Typography
                  variant="h6"
                  gutterBottom
                  sx={{ fontWeight: 500 }}
                  align="center"
                >
                  {item.title}
                </Typography>
                <Typography align="center" color="text.secondary">
                  {item.subtitle}
                </Typography>
              </Box>
            </Box>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default Services;
