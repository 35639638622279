import { Box, Container, useTheme } from '@mui/material';
import React from 'react';
import Spotlight from './Spotlight/Spotlight';
import featureBuyStocks from './featureBuyStocks.png';
import featureRebalancePortfolio from './featureRebalancePortfolio.png';
import featureDrip from './featureDrip.png';
import featureWhen from './featureWhen.png';
import Footer from '../Footer/Footer';

const featuresData = [
  {
    title: 'Send orders according to a schedule',
    subtitle: 'Pick the stocks you want to invest in and specify where and when to invest.',
    image: featureBuyStocks,
  },
  {
    title: 'Schedule your orders when it\'s convenient for you',
    subtitle: 'You can do this by specifying a recurring day (weekly, monthly...) or when a certain amount is deposited into your account',
    image: featureWhen,
  },
  {
    title: 'Keep your portfolio balanced by investing money',
    subtitle: 'Ensure that the stocks in your portfolio are weighted appropriately. Your portfolio will stay balanced by acquiring new stocks.',
    image: featureRebalancePortfolio,
  },
  {
    title: 'Make sure there is no money sleeping in your accounts',
    subtitle: 'By rebalancing your portfolio, you ensure that there is no money left over. Your dividends will be reinvested.',
    image: featureDrip,
  },
];

const Features = () => {
  const theme = useTheme();
  return (
    <Box
      sx={{
        backgroundColor: theme.palette.alternate.main,
        overflowY: 'auto',
      }}
    >
      {featuresData.map(({ title, subtitle, image }, index) => (
        <Container key={title} style={{ paddingTop: 48 }}>
          <Spotlight title={title} subtitle={subtitle} image={image} alignLeft={index % 2 === 0} />
        </Container>
      ))}
      <Box
        component="svg"
        preserveAspectRatio="none"
        xmlns="http://www.w3.org/2000/svg"
        x="0px"
        y="0px"
        viewBox="0 0 1920 100.1"
        sx={{
          width: '100%',
          marginBottom: theme.spacing(-1),
        }}
      >
        <path
          fill={theme.palette.background.paper}
          d="M0,0c0,0,934.4,93.4,1920,0v100.1H0L0,0z"
        />
      </Box>
      <div />
      <Box
        paddingY={2}
        sx={{
          backgroundColor: theme.palette.background.paper,
          overflowY: 'auto',
        }}
      >
        <Container paddingY={2}>
          <Footer />
        </Container>
      </Box>
    </Box>
  );
};

export default Features;
