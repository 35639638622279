import React from 'react';
import styled from 'styled-components';
import Button from '@mui/material/Button';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Card, useTheme } from '@mui/material';
import { BOTTOM_NAVIGATION_HEIGHT, useMobileDevice } from '../../Common/responsiveUIHelper';
import { selectAuth } from '../AutoInvestSlice';
import { WEALTHSIMPLE } from '../constants';

const AccountsSplashScreenWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  height: ${({ isMobileDevice }) => (isMobileDevice ? `calc(100% - ${BOTTOM_NAVIGATION_HEIGHT})` : '100%')};
`;

const AccountCard = styled(Card)`
  max-width: 496px;
  padding: 15px;
`;

const AccountsSplashScreen = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobileDevice = useMobileDevice();

  const authSelector = useSelector(selectAuth);
  return (
    <AccountsSplashScreenWrapper isMobileDevice={isMobileDevice}>
      <AccountCard>
        <h2 style={{ color: theme.palette.primary.main }}>Get started!</h2>
        { !authSelector[WEALTHSIMPLE].isLogged
          ? (
            <>
              <h4 style={{ padding: '0 12px 0 12px', color: theme.palette.text.primary }}>
                1. Link your broker account to start investing.
              </h4>
              <Button variant="outlined" type="submit" value="Submit" onClick={() => navigate('/settings')} sx={{ m: 1, width: '19ch' }}>Link Account</Button>
            </>
          )
          : null}
        <h4 style={{ padding: '0 12px 0 12px', color: theme.palette.text.primary }}>
          2. Add a new investing Strategy and you will
          be already automatically investing your deposits.
        </h4>
        <Button variant="outlined" type="submit" value="Submit" onClick={() => navigate('/strategies/new')} sx={{ m: 1, width: '19ch' }}>Add Strategy</Button>
      </AccountCard>
    </AccountsSplashScreenWrapper>
  );
};

export default AccountsSplashScreen;
