import React from 'react';
import PropTypes from 'prop-types';
import {
  Accordion, AccordionDetails, AccordionSummary, Button, Typography,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { makeStyles } from '@mui/styles';
import AddIcon from '@mui/icons-material/Add';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { accountBalancesProps } from '../../Utils/Utils';
import AccountDetails from './AccountDetails';
import AccountStrategy from './AccountStrategy';
import { useMobileDevice } from '../../../Common/responsiveUIHelper';
import splashScreenImg from './AccounStrategesSplashScreen.jpg';

const useStyles = makeStyles(() => ({
  content: {
    justifyContent: 'space-between',
    alignItems: 'center',
  },
}));

const AccountStrategies = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
`;

const AccountStrategiesSplashScreen = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-self: center;
  width: 100%;
  justify-content: space-evenly;
  height: 100%;
  min-height: ${({ isMobileDevice }) => (isMobileDevice ? '250px' : '0')};
`;

const Account = ({
  accountKey,
  broker,
  account,
  accountStrategies,
  accountBalances,
  testStrategy,
  copyStrategy,
  removeStrategy,
  isTestStratLoading,
  setStrategyActivated,
  isStrategyActivating,
}) => {
  const navigate = useNavigate();
  const isMobileDevice = useMobileDevice();
  const classes = useStyles();
  const [expanded, setExpanded] = React.useState(true);

  const editStrategy = (id) => {
    navigate(`/strategies/${id}`);
  };

  const createAccountStrategy = () => {
    navigate(`/strategies/${broker}/${account}/strategies/new`);
  };
  return (
    <Accordion
      expanded={expanded}
      onChange={(e, isExpanded) => setExpanded(!!isExpanded)}
      key={accountKey}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        classes={{ content: classes.content }}
      >
        <Typography variant="h6">{accountKey}</Typography>
        {((accountStrategies && Object.keys(accountStrategies).length > 0) || !expanded)
        && (
          <Button
            onClick={() => { createAccountStrategy(); }}
            variant="contained"
            size="small"
            sx={{
              height: '30.75px',
              marginRight: '15px',
            }}
          >
            <AddIcon sx={{ mr: 1 }} />
            Strategy
          </Button>
        )}
      </AccordionSummary>
      <AccordionDetails sx={{
        p: 0, display: 'flex', flexDirection: isMobileDevice ? 'column' : 'row', minHeight: 300,
      }}
      >
        <AccountDetails accountBalances={accountBalances} />
        <AccountStrategies>
          { accountStrategies && Object.keys(accountStrategies).length > 0
            ? Object.values(accountStrategies).map((strategy) => {
              const { id } = strategy;
              return (
                <AccountStrategy
                  key={id}
                  strategy={strategy}
                  testStrategy={() => { testStrategy(id, broker); }}
                  editStrategy={() => { editStrategy(id); }}
                  copyStrategy={() => { copyStrategy(id); }}
                  removeStrategy={() => { removeStrategy(id); }}
                  isTestStratLoading={isTestStratLoading && isTestStratLoading[id]}
                  setStrategyActivated={(event) => { setStrategyActivated(event, id, broker); }}
                  isStrategyActivating={isStrategyActivating && isStrategyActivating[id]}
                  accountBalances={accountBalances}
                />
              );
            })
            : (
              <AccountStrategiesSplashScreen isMobileDevice={isMobileDevice}>
                <Typography variant="h6" sx={{ maxWidth: 300 }}>
                  Start Investing Now
                </Typography>
                <img src={splashScreenImg} alt="freedom" style={{ borderRadius: '50%', width: 150, height: 150 }} />
                <Button
                  onClick={() => { createAccountStrategy(); }}
                  variant="contained"
                  size="small"
                  sx={{
                    height: '30.75px',
                  }}
                >
                  <AddIcon />
                  Strategy
                </Button>
              </AccountStrategiesSplashScreen>
            )}
        </AccountStrategies>
      </AccordionDetails>
    </Accordion>
  );
};

Account.defaultProps = {
  accountBalances: {},
  accountStrategies: {},
};

Account.propTypes = {
  accountBalances: accountBalancesProps,
  account: PropTypes.string.isRequired,
  accountKey: PropTypes.string.isRequired,
  accountStrategies: PropTypes.objectOf(PropTypes.shape({
    autoStrategy: PropTypes.arrayOf(PropTypes.shape({
      amount: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      orderType: PropTypes.number.isRequired,
      stock: PropTypes.oneOfType([PropTypes.object, PropTypes.string]).isRequired,
    })).isRequired,
  })),
  broker: PropTypes.string.isRequired,
  copyStrategy: PropTypes.func.isRequired,
  isStrategyActivating: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]).isRequired,
  isTestStratLoading: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]).isRequired,
  removeStrategy: PropTypes.func.isRequired,
  setStrategyActivated: PropTypes.func.isRequired,
  testStrategy: PropTypes.func.isRequired,
};

export default Account;
