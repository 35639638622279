import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Link, useNavigate, useParams } from 'react-router-dom';
import PublishIcon from '@mui/icons-material/Publish';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import TextField from '@mui/material/TextField';
import EditIcon from '@mui/icons-material/Edit';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import {
  collection, addDoc, serverTimestamp, setDoc, doc,
} from 'firebase/firestore';
import styled from 'styled-components';
import { connect, useDispatch, useSelector } from 'react-redux';
import dayjs from 'dayjs';
import { getAnalytics, logEvent } from 'firebase/analytics';
import {
  Button,
  Card, FormControlLabel, FormHelperText, FormLabel,
  Paper, Radio, RadioGroup, Typography, useTheme,
} from '@mui/material';
import { useFirestoreConnect } from 'react-redux-firebase';
import { LoadingButton } from '@mui/lab';
import {
  InputMoneyFormat, InputPercentFormat, capitalize, useInput,
} from '../Utils/Utils';
import {
  QUESTRADE, STRATEGIES, TRIGGERS, WEALTHSIMPLE,
} from '../constants';
import { useMobileDevice } from '../../Common/responsiveUIHelper';
import { getSecurityInfo } from '../AutoInvestAPI';
import {
  getBrokerAccounts, selectAuth,
  selectBrokerAccounts,
  selectBrokersBalances,
  setSnackbar,
} from '../AutoInvestSlice';
import InvestStrategySecuritiesUntracked from './InvestStrategySecuritiesUntracked';
import InvestStrategySecurities from './InvestStrategySecurities';

const analytics = getAnalytics();

const InvestStrategyWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  overflow-y: auto;
  height: ${({ $isMobileDevice }) => ($isMobileDevice ? 'calc(100% - 120px)' : '100%')};
  padding-top: ${({ $isMobileDevice }) => ($isMobileDevice ? '60px' : '15px')};
  padding-bottom: ${({ $isMobileDevice }) => ($isMobileDevice ? '0px' : '15px')};
  background-color: ${(props) => props.theme.palette.background.default};
  ${({ $isMobileDevice }) => ($isMobileDevice ? 'margin-top: 1px' : null)};
  `;

const InvestStrategyCard = styled(Card)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  overflow-y: auto;
  padding-top: ${({ $isMobileDevice }) => ($isMobileDevice ? '100px' : 'auto')};
  margin: ${({ $isMobileDevice }) => ($isMobileDevice ? '0px' : 'auto')};
`;

const InvestStrategyContainer = styled.div`
  display: flex;
  flex-direction: ${({ $isMobileDevice }) => ($isMobileDevice ? 'column' : 'row')};
`;

const InvestStrategyDetails = styled.div`
  /* ${({ $isMobileDevice }) => ($isMobileDevice ? 'align-self: flex-start;' : null)} */
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const InvestStrategySecuritiesWrapper = styled.div`
`;

const TitleNavigation = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 12px;
  margin-top: 12px;
`;

const FormGroup = styled.div`
  padding: 0px 12px 12px 12px;
  display: flex;
  flex-wrap: wrap;
  justify-content: ${({ isMobileDevice }) => (isMobileDevice ? 'flex-start' : 'center')};
  align-items: flex-start;
`;

const RadioElems = styled.div`
  display: flex;
  align-items: center;
`;

const InputSide = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
`;

const InvestStrategy = ({
  db, auth, editMode, accounted,
}) => {
  let strategyId = false;
  let defaultBroker = null;
  let defaultAccountId = null;
  if (accounted) {
    const params = useParams();
    defaultBroker = params.broker;
    defaultAccountId = params.accountId;
  }
  const navigate = useNavigate();
  const theme = useTheme();
  const dispatch = useDispatch();
  const authSelector = useSelector(selectAuth);
  const QTAccounts = useSelector(selectBrokerAccounts(QUESTRADE));
  const accounts = useSelector(selectBrokersBalances);
  const [autoStrategy, setAutoStrategy] = useState([]);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [buyOnly, setBuyOnly] = useState(true);
  const isMobileDevice = useMobileDevice();
  const {
    value: nickname, bind: bindNickname,
    reset: resetNickname, setValue: setNickname,
  } = useInput('');
  const {
    value: broker, reset: resetBroker, setValue: setBroker,
  } = useInput(accounted ? defaultBroker : '');
  const {
    value: account, bind: bindAccount, reset: resetAccount, setValue: setAccount,
  } = useInput(accounted ? defaultAccountId : '');
  const {
    value: triggerType, bind: bindTriggerType, reset: resetTriggerType, setValue: setTriggerType,
  } = useInput(TRIGGERS.RECURRENCE);
  const {
    value: amountTrigger, bind: bindAmountTrigger,
    reset: resetAmountTrigger, setValue: setAmountTrigger,
  } = useInput('');
  const {
    value: recurrenceFrequency, bind: bindRecurrenceFrequency,
    reset: resetRecurrenceFrequency, setValue: setRecurrenceFrequency,
  } = useInput('');
  const [startingDate, setStartingDate] = useState(null);
  const resetStartingDate = () => setStartingDate(null);
  const {
    value: strategyType, setValue: setStrategyType, reset: resetStrategyType,
  } = useInput(STRATEGIES.REBALANCE);
  const {
    value: amountFrozen, bind: bindAmountFrozen,
    reset: resetAmountFrozen, setValue: setAmountFrozen,
  } = useInput('0');
  const {
    value: percentTolerance, bind: bindPercentTolerance,
    reset: resetPercentTolerance, setValue: setPercentTolerance,
  } = useInput(1);
  const [activated, setActivated] = useState(false);

  if (editMode) {
    strategyId = useParams().strategyId;
    useFirestoreConnect([
      {
        collection: 'strategies',
        doc: strategyId,
      },
    ]);
    const strategiesFirestore = useSelector((state) => state.firestore);
    const strategy = strategiesFirestore.ordered.strategies
     && strategiesFirestore.ordered.strategies[0];

    useEffect(() => {
      if (strategy) {
        setNickname(strategy.nickname);
        setBroker(strategy.broker);
        setStrategyType(strategy.strategyType);
        setAccount(strategy.account);
        setAmountFrozen(strategy.amountFrozen);
        setPercentTolerance(strategy.percentTolerance);
        setAutoStrategy(strategy.autoStrategy);
        setTriggerType(strategy.triggerType);
        setActivated(strategy.activated);
        setBuyOnly(strategy.buyOnly ? 'true' : 'false');
        if (strategy.triggerType === TRIGGERS.RECURRENCE) {
          setRecurrenceFrequency(strategy.recurrenceFrequency);
          setStartingDate(dayjs(strategy.startingDate));
        } else if (strategy.triggerType === TRIGGERS.DEPOSIT) {
          setAmountTrigger(strategy.amountTrigger);
        }
      }
    }, [strategy]);
  }

  useEffect(() => {
    dispatch(getBrokerAccounts(QUESTRADE));
  }, [auth]);
  const [error, setError] = useState({
    amountTrigger: false,
    account: false,
  });

  const handleStrategyTypeChange = (event) => {
    if (event.target.value !== strategyType) {
      setAutoStrategy([]);
    }
    setStrategyType(event.target.value);
  };

  const handleBrokerChange = (event) => {
    if (event.target.value === QUESTRADE) {
      setTriggerType(TRIGGERS.RECURRENCE);
    }
    resetAccount();
    setBroker(event.target.value);
  };

  const saveInvestingStrategy = async (evt) => {
    try {
      evt.preventDefault();
      const errorSrc = {
        amountTrigger: false,
        account: false,
      };

      const verifySecuritiesAreValid = async () => {
        // eslint-disable-next-line no-restricted-syntax
        for (const { stock }
          of autoStrategy) {
          // eslint-disable-next-line no-await-in-loop
          const results = await getSecurityInfo(stock, broker);
          if (results instanceof Error) {
            dispatch(setSnackbar({
              open: true,
              severity: 'error',
              message: results.message,
            }));
            setSubmitLoading(false);
            return true;
          }
        }
        return false;
      };
      if (broker === '') {
        errorSrc.broker = true;
      }
      if (account === '') {
        errorSrc.account = true;
      }
      if (triggerType === '') {
        errorSrc.triggerType = true;
      }
      if (strategyType === '') {
        errorSrc.strategyType = true;
      }
      if (triggerType === TRIGGERS.RECURRENCE && recurrenceFrequency === '') {
        errorSrc.recurrenceFrequency = true;
      }
      if (triggerType === TRIGGERS.RECURRENCE && startingDate === null) {
        errorSrc.startingDate = true;
      }
      if (triggerType === TRIGGERS.DEPOSIT && amountTrigger === '') {
        errorSrc.amountTrigger = true;
      }
      setError(errorSrc);

      if (errorSrc.account !== true && errorSrc.strategyType !== true
        && errorSrc.triggerType !== true
        && ((triggerType === TRIGGERS.DEPOSIT && errorSrc.amountTrigger !== true)
          || (triggerType === TRIGGERS.RECURRENCE && errorSrc.recurrenceFrequency !== true
            && errorSrc.startingDate !== true)
        )
      ) {
        if (authSelector[broker].isLogged) {
          try {
            setSubmitLoading(true);
            errorSrc.stocks = await verifySecuritiesAreValid();
          } catch (err) {
            dispatch(setSnackbar({
              open: true,
              severity: 'error',
              message: err.message,
            }));
            setSubmitLoading(false);
          }
        }
        if (!errorSrc.stocks) {
          const docData = {
            ...!editMode && { created: serverTimestamp() },
            updated: serverTimestamp(),
            ...triggerType === TRIGGERS.DEPOSIT && { amountTrigger },
            ...strategyType === STRATEGIES.REBALANCE && { percentTolerance },
            ...strategyType === STRATEGIES.REBALANCE && { amountFrozen },
            ...triggerType === TRIGGERS.RECURRENCE && { startingDate: startingDate.valueOf() },
            nickname,
            strategyType,
            autoStrategy,
            triggerType,
            recurrenceFrequency,
            uid: auth.uid,
            broker,
            account,
            buyOnly: buyOnly !== 'false',
            activated: (editMode && activated)
           || (!editMode && authSelector[broker].isLogged),
          };

          if (editMode) {
            await setDoc(await doc(db, 'strategies', strategyId), docData, { merge: true });
          } else {
            await addDoc(collection(db, 'strategies'), docData);
          }

          setAutoStrategy([]);
          resetAmountTrigger();
          resetAmountFrozen();
          resetPercentTolerance();
          resetAccount();
          resetTriggerType();
          resetStrategyType();
          resetBroker();
          resetRecurrenceFrequency();
          resetStartingDate();
          resetNickname();
          setBuyOnly('true');
          setSubmitLoading(false);
          logEvent(analytics, 'add_strategy');
          navigate('/');
        }
      }
    } catch (e) {
      console.error('Error adding document: ', e);
    }
  };

  const getTriggersSubFields = (selectedTrigger) => {
    const triggersSubfields = {
      [TRIGGERS.DEPOSIT]: (
        <TextField
          error={error.amountTrigger}
          id="outlined-basic"
          label="Amount"
          variant="outlined"
          {...bindAmountTrigger}
          InputProps={{
            inputComponent: InputMoneyFormat,
          }}
          sx={{ m: 1, width: '22ch' }}
        />
      ),
      [TRIGGERS.RECURRENCE]: (
        <>
          <FormControl sx={{ m: 1, width: '22ch' }} error={error.recurrenceFrequency}>
            <InputLabel>Frequency</InputLabel>
            <Select
              label="Recurrence"
              {...bindRecurrenceFrequency}
            >
              <MenuItem value="daily">Daily</MenuItem>
              <MenuItem value="weekly">Weekly</MenuItem>
              <MenuItem value="biweekly">Biweekly</MenuItem>
              <MenuItem value="monthly">Monthly</MenuItem>
              <MenuItem value="annually">Annually</MenuItem>
            </Select>
          </FormControl>
          <FormControl sx={{ m: 1, width: '22ch' }}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                label="Starting date"
                value={startingDate}
                minDate={dayjs().add(1, 'day')}
                onChange={(newDate) => setStartingDate(newDate)}
                error={error.startingDate}
                slotProps={{ textField: { variant: 'outlined' } }}
              />
            </LocalizationProvider>
          </FormControl>
        </>
      ),
    };
    return triggersSubfields[selectedTrigger] ? triggersSubfields[selectedTrigger] : null;
  };

  const getTitle = () => {
    let title = 'Add Investing Strategy';
    if (accounted) {
      title = `${capitalize(broker)} ${account.toUpperCase()}: Add Investing Strategy`;
    } else {
      title = `${editMode ? 'Edit' : 'Add'} Investing Strategy`;
    }
    return title;
  };

  return (
    <InvestStrategyWrapper theme={theme} $isMobileDevice={isMobileDevice}>
      <InvestStrategyCard $isMobileDevice={isMobileDevice}>
        {!isMobileDevice ? (
          <TitleNavigation>
            <Typography
              variant={isMobileDevice ? 'h5' : 'h5'}
              component="div"
              style={{ margin: 8, marginLeft: isMobileDevice ? 8 : 40, marginBottom: 0 }}
            >
              {getTitle()}
            </Typography>
            <IconButton
              component={Link}
              to="/strategies"
              size="large"
              sx={{ mr: 1 }}
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
          </TitleNavigation>
        ) : null}
        <InvestStrategyContainer $isMobileDevice={isMobileDevice}>
          <InvestStrategyDetails $isMobileDevice={isMobileDevice}>
            { editMode ? null
              : (
                <FormGroup $isMobileDevice={isMobileDevice}>
                  <FormLabel
                    id="strategy label"
                    sx={{ m: isMobileDevice ? 0 : 2, width: isMobileDevice ? '12ch' : '10ch', alignSelf: 'center' }}
                  >
                    Strategy Type
                  </FormLabel>
                  <InputSide>
                    <FormControl sx={{ m: 1 }} error={error.strategyType}>
                      <RadioGroup
                        aria-labelledby="Strategy Type"
                        value={strategyType}
                        onChange={handleStrategyTypeChange}
                      >
                        <RadioElems>
                          <FormControlLabel sx={{ width: '21ch' }} value={STRATEGIES.REBALANCE} control={<Radio />} label="Rebalance Account" />
                          <FormHelperText sx={{ maxWidth: '48ch' }}>
                            Rebalance account by investing available funds towards
                            securities to preserve a desired asset allocation ratio
                          </FormHelperText>
                        </RadioElems>
                        <RadioElems>
                          <FormControlLabel sx={{ width: '21ch' }} value={STRATEGIES.BUY_STOCKS} control={<Radio />} label="Buy Securities" />
                          <FormHelperText sx={{ maxWidth: '48ch' }}>Invest in multiple securities</FormHelperText>
                        </RadioElems>
                      </RadioGroup>
                      <FormHelperText>{error.strategyType && 'Please select an option'}</FormHelperText>
                    </FormControl>
                  </InputSide>
                </FormGroup>
              )}
            {accounted || editMode ? null
              : (
                <FormGroup>
                  {isMobileDevice ? null : <FormLabel id="account label" sx={{ m: 2, width: '10ch' }}>Account</FormLabel>}
                  <FormControl sx={{ m: 1, width: '15ch' }} error={error.Broker}>
                    <InputLabel>Broker</InputLabel>
                    <Select
                      label="Broker"
                      value={broker}
                      onChange={handleBrokerChange}
                    >
                      <MenuItem value={WEALTHSIMPLE}>Wealthsimple</MenuItem>
                      <MenuItem value={QUESTRADE}>Questrade</MenuItem>
                    </Select>
                  </FormControl>
                  <FormControl sx={{ m: 1, width: '22ch' }} error={error.account}>
                    <InputLabel>Account</InputLabel>
                    <Select
                      label="Account"
                      {...bindAccount}
                    >
                      {
                  broker === QUESTRADE
                    ? Object.entries(QTAccounts).map(([key, acc]) => (
                      <MenuItem key={`${acc.type} ${key}`} value={key}>
                        {`${acc.type} ${key}`}
                      </MenuItem>
                    ))
                    : [
                      <MenuItem key="personal" value="personal">Personal</MenuItem>,
                      <MenuItem key="tfsa" value="tfsa">TFSA</MenuItem>,
                      <MenuItem key="rrsp" value="rrsp">RRSP</MenuItem>,
                    ]
                  }
                    </Select>
                  </FormControl>
                </FormGroup>
              )}
            { strategyType === STRATEGIES.REBALANCE
              ? (
                <FormGroup style={{ width: isMobileDevice ? '100%' : 'auto', ...isMobileDevice && { padding: '0px 0px 12px 0px' } }}>
                  <FormLabel id="strategy label" sx={{ m: isMobileDevice ? 0 : 2, width: '10ch' }}>Strategy</FormLabel>
                  <InputSide style={{ width: isMobileDevice ? '100%' : 'auto' }}>
                    <TextField
                      id="outlined-basic"
                      label="Preserved Money"
                      variant="outlined"
                      {...bindAmountFrozen}
                      InputProps={{
                        inputComponent: InputMoneyFormat,
                      }}
                      sx={{ m: 1, width: '22ch' }}
                    />
                    <FormControl sx={{ m: 1 }} error={error.strategyType}>
                      <RadioGroup
                        aria-labelledby="Buy only"
                        value={buyOnly}
                        onChange={(e) => setBuyOnly(e.target.value)}
                      >
                        <RadioElems>
                          <FormControlLabel sx={{ width: '15ch' }} value="true" control={<Radio />} label="Buy only" />
                          <FormHelperText sx={{ maxWidth: '48ch' }}>Utilize only available funds for investment </FormHelperText>
                        </RadioElems>
                        <RadioElems>
                          <FormControlLabel sx={{ width: '15ch' }} value="false" control={<Radio />} label="Buy and sell" />
                          <FormHelperText sx={{ maxWidth: '48ch' }}>
                            Match targets by selling and buying securities
                          </FormHelperText>
                        </RadioElems>
                      </RadioGroup>
                      <FormHelperText>{error.strategyType && 'Please select an option'}</FormHelperText>
                    </FormControl>
                    <FormControl sx={{ flexDirection: 'row', alignItems: 'center' }}>
                      <TextField
                        id="outlined-basic"
                        label="Percentage Tolerance"
                        variant="outlined"
                        disabled={buyOnly !== 'false'}
                        {...bindPercentTolerance}
                        InputProps={{
                          inputComponent: InputPercentFormat,
                          inputProps: { min: 1, max: 100 },
                        }}
                        sx={{ m: 1, width: '16ch' }}
                      />
                      <FormHelperText sx={{ maxWidth: '48ch' }}>
                        Difference between reality and its target
                        before selling securities to rebalance
                      </FormHelperText>
                    </FormControl>
                  </InputSide>
                </FormGroup>
              ) : null}
            <FormGroup>
              {isMobileDevice ? null : <FormLabel id="when label" sx={{ m: 2, width: '10ch', alignSelf: 'center' }}>Recurrence</FormLabel>}
              <InputSide>
                <FormControl sx={{ m: 1, width: '22ch' }} error={error.triggerType} disabled={broker === QUESTRADE}>
                  <InputLabel>Recurrence</InputLabel>
                  <Select
                    label="Recurrence"
                    {...bindTriggerType}
                    defaultValue={broker === QUESTRADE ? TRIGGERS.RECURRENCE : false}
                  >
                    <MenuItem value={TRIGGERS.RECURRENCE}>Recurring period</MenuItem>
                    <MenuItem value={TRIGGERS.DEPOSIT}>On deposit</MenuItem>
                  </Select>
                </FormControl>
                {getTriggersSubFields(triggerType)}
              </InputSide>
            </FormGroup>
            <FormGroup style={{ paddingBottom: 0 }}>
              {isMobileDevice ? null : <FormLabel id="Strategy Name" sx={{ m: 2, width: '10ch' }}>Strategy Name</FormLabel>}
              <TextField
                id="outlined-basic"
                label="Strategy Name"
                variant="outlined"
                {...bindNickname}
                sx={{ m: 1, width: '30ch' }}
              />
            </FormGroup>
          </InvestStrategyDetails>
          <InvestStrategySecuritiesWrapper>
            <InvestStrategySecurities
              broker={broker}
              setAutoStrategy={setAutoStrategy}
              accountBalance={accounts?.[broker]?.[account] || {}}
              triggerType={triggerType}
              autoStrategy={autoStrategy}
              strategyType={strategyType}
              error={error.stocks}
            />
            <InvestStrategySecuritiesUntracked
              setAutoStrategy={setAutoStrategy}
              accountBalance={accounts?.[broker]?.[account] || {}}
              autoStrategy={autoStrategy}
              strategyType={strategyType}
            />
          </InvestStrategySecuritiesWrapper>
        </InvestStrategyContainer>
        {!isMobileDevice ? (
          <div style={{ alignSelf: 'flex-end' }}>
            <Button variant="outlined" component={Link} to="/strategies" size="large">Cancel</Button>
            <LoadingButton
              variant="outlined"
              loading={submitLoading}
              loadingPosition="end"
              endIcon={editMode ? <EditIcon /> : <PublishIcon />}
              onClick={(event) => saveInvestingStrategy(event)}
              sx={{ m: 1, width: '26ch', alignSelf: 'flex-end' }}
            >
              {editMode ? 'Update' : 'Save'}
              {' '}
              Strategy
            </LoadingButton>
          </div>
        )
          : (
            <Paper
              sx={{
                position: 'fixed', bottom: 0, left: 0, right: 0, zIndex: 1000, height: 60, display: 'flex', justifyContent: 'flex-end', alignItems: 'center',
              }}
              elevation={3}
            >
              <Button variant="outlined" component={Link} to="/strategies" size="large">Cancel</Button>
              <LoadingButton
                variant="outlined"
                loading={submitLoading}
                loadingPosition="end"
                endIcon={editMode ? <EditIcon /> : <PublishIcon />}
                onClick={(event) => saveInvestingStrategy(event)}
                sx={{ m: 1, width: '26ch', marginRight: 4 }}
              >
                {editMode ? 'Update' : 'Save'}
                {' '}
                Strategy
              </LoadingButton>
            </Paper>
          )}
      </InvestStrategyCard>
    </InvestStrategyWrapper>
  );
};

InvestStrategy.defaultProps = {
  editMode: false,
  accounted: false,
};

InvestStrategy.propTypes = {
  db: PropTypes.shape({
    collection: PropTypes.func,
  }).isRequired,
  auth: PropTypes.shape({
    uid: PropTypes.string.isRequired,
  }).isRequired,
  editMode: PropTypes.bool,
  accounted: PropTypes.bool,
};

const enhance = connect(
  // Map redux state to component props
  ({ firebase: { auth } }) => ({
    auth,
  }),
);

export default enhance(InvestStrategy);
