/* eslint-disable react/no-unescaped-entities */
import PropTypes from 'prop-types';
import React from 'react';
import { useFormik } from 'formik';
import * as yup from 'yup';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
// import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { useTheme } from '@mui/material/styles';
import { collection, addDoc } from 'firebase/firestore';
import { useNavigate } from 'react-router-dom';
import Container from '../Container';
import Footer from '../Footer/Footer';

const validationSchema = yup.object({
  name: yup
    .string()
    .trim()
    .min(2, 'Please enter a valid name')
    .max(50, 'Please enter a valid name')
    .required('Please specify your name'),
  subject: yup
    .string()
    .trim()
    .required('Please specify the subject'),
  email: yup
    .string()
    .trim()
    .email('Please enter a valid email address')
    .required('Email is required.'),
  message: yup
    .string()
    .trim()
    .required('Please specify your message'),
});

const Contact = ({ db }) => {
  const theme = useTheme();
  const navigate = useNavigate();

  const initialValues = {
    name: '',
    subject: '',
    email: '',
    message: '',
  };

  const onSubmit = async ({
    name, email, subject, message,
  }) => {
    await addDoc(collection(db, 'mail'), {
      to: 'contactus@wealthlucid.com',
      message: {
        subject: 'WL: User sent message',
        text: `
        Name: ${name}
        Email: ${email}
        Subject: ${subject}
        Message: ${message}
        `,
      },
    });
    navigate(-1);
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
  });

  return (
    <Box
      sx={{
        backgroundColor: theme.palette.background.paper,
        paddingTop: 2,
        overflowY: 'auto',
      }}
    >
      <Box
        maxWidth={600}
        margin="0 auto"
      >
        <Box marginBottom={4}>
          <Typography
            variant="h3"
            sx={{ fontWeight: 700 }}
            align="center"
            gutterBottom
          >
            How can we help?
          </Typography>
          <Typography color="text.secondary" align="center">
            You have a question? you have and idea?
            You want to give some feedback? We'd love to hear from you.
          </Typography>
        </Box>
        <Box>
          <form onSubmit={formik.handleSubmit}>
            <Grid container spacing={4}>
              <Grid item xs={12}>
                <TextField
                  sx={{ height: 54 }}
                  label="Your name"
                  variant="outlined"
                  color="primary"
                  size="medium"
                  name="name"
                  fullWidth
                  value={formik.values.name}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.name && Boolean(formik.errors.name)
                  }
                  helperText={formik.touched.name && formik.errors.name}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  sx={{ height: 54 }}
                  label="Your email address"
                  type="email"
                  variant="outlined"
                  color="primary"
                  size="medium"
                  name="email"
                  fullWidth
                  value={formik.values.email}
                  onChange={formik.handleChange}
                  error={formik.touched.email && Boolean(formik.errors.email)}
                  helperText={formik.touched.email && formik.errors.email}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  sx={{ height: 54 }}
                  label="Subject"
                  variant="outlined"
                  color="primary"
                  size="medium"
                  name="subject"
                  fullWidth
                  value={formik.values.subject}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.subject && Boolean(formik.errors.subject)
                  }
                  helperText={formik.touched.subject && formik.errors.subject}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="How can we help?"
                  multiline
                  rows={6}
                  variant="outlined"
                  color="primary"
                  size="medium"
                  name="message"
                  fullWidth
                  value={formik.values.message}
                  onChange={formik.handleChange}
                  error={formik.touched.message && Boolean(formik.errors.message)}
                  helperText={formik.touched.message && formik.errors.message}
                />
              </Grid>
              <Grid item container justifyContent="center" xs={12}>
                <Button
                  sx={{ height: 54, minWidth: 150 }}
                  variant="contained"
                  color="primary"
                  size="medium"
                  type="submit"
                >
                  Submit
                </Button>
              </Grid>
              <Grid item container justifyContent="center" xs={12}>
                <Typography color="text.secondary">
                  We'll get back to you in 1-2 business days.
                </Typography>
              </Grid>
              {/* <Grid item xs={12}>
                <Divider />
              </Grid>
              <Grid item container justifyContent="center" xs={12}>
                <Box>
                  <Typography component="p" variant="body2" align="left">
                    By clicking on "submit" you agree to our
                    {' '}
                    <Box
                      component="a"
                      href=""
                      color={theme.palette.text.primary}
                      fontWeight="700"
                    >
                      Privacy Policy
                    </Box>
                    ,
                    {' '}
                    <Box
                      component="a"
                      href=""
                      color={theme.palette.text.primary}
                      fontWeight="700"
                    >
                      Data Policy
                    </Box>
                    {' '}
                    and
                    {' '}
                    <Box
                      component="a"
                      href=""
                      color={theme.palette.text.primary}
                      fontWeight="700"
                    >
                      Cookie Policy
                    </Box>
                    .
                  </Typography>
                </Box>
              </Grid> */}
            </Grid>
          </form>
        </Box>
      </Box>
      <Box bgcolor={theme.palette.background.paper}>
        <Container style={{ paddingBottom: 16 }}>
          <Footer />
        </Container>
      </Box>
    </Box>
  );
};

Contact.propTypes = {
  db: PropTypes.shape({
    collection: PropTypes.func,
  }).isRequired,
};

export default Contact;
