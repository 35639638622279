import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  PieChart, Pie, Cell, Sector,
} from 'recharts';
import { fixNb } from '../../Utils/Utils';

const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042'];

const renderActiveShape = (props) => {
  const {
    cx, cy, innerRadius, outerRadius, startAngle, endAngle, fill, symbol, percent, price,
  } = props;
  const center = 5;
  return (
    <>
      <text x={cx} y={cy} dy={center - 17} textAnchor="middle" fill={fill} fontSize="0.875rem">
        {symbol}
      </text>
      <text x={cx} y={cy} dy={center} textAnchor="middle" fill={fill} fontSize="0.875rem">
        {`${fixNb(price)}$`}
      </text>
      <text x={cx} y={cy} dy={center + 17} textAnchor="middle" fill={fill} fontSize="0.875rem">
        {`${fixNb(percent * 100)}%`}
      </text>
      <Sector
        cx={cx}
        cy={cy}
        innerRadius={innerRadius}
        outerRadius={outerRadius}
        startAngle={startAngle}
        endAngle={endAngle}
        fill={fill}
      />
    </>
  );
};

const AccountPieChart = ({ data }) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const radiusBase = 42;

  const onPieEnter = (event, index) => {
    setActiveIndex(index);
  };

  return (
    <PieChart width={110} height={110}>
      <Pie
        dataKey="price"
        isAnimationActive={false}
        activeIndex={activeIndex}
        activeShape={renderActiveShape}
        data={data}
        cx="50%"
        cy="50%"
        innerRadius={radiusBase}
        outerRadius={radiusBase + 13}
        fill="#8884d8"
        paddingAngle={1}
        onMouseEnter={onPieEnter}
        labelLine={false}
      >
        {data.map((entry, index) => (
          <Cell key={`cell-${entry.symbol}`} fill={COLORS[index % COLORS.length]} />
        ))}
      </Pie>
    </PieChart>
  );
};

AccountPieChart.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({
    price: PropTypes.number,
    symbol: PropTypes.string,
  })).isRequired,
};

export default AccountPieChart;
