import { createSlice } from '@reduxjs/toolkit';
import {
  sendSubmitOrder, fetchBrokerLogout, fetchBrokerLoginStatus,
  fetchBrokerAccounts, fetchBrokerBalances,
} from './AutoInvestAPI';
import { QUESTRADE, WEALTHSIMPLE } from './constants';

const SNACKBAR_SUCCESS = 'success';
const SNACKBAR_ERROR = 'error';

const initialState = {
  snackbar: {
    open: false,
    severity: 'success',
    message: '',
  },
  auth: {
    [WEALTHSIMPLE]: {
      isLoading: false,
      isLogged: false,
    },
    [QUESTRADE]: {
      isLoading: false,
      isLogged: false,
    },
  },
  brokerAccounts: {
    [QUESTRADE]: {
      Primary: { type: '' },
    },
  },
  brokerBalances: {
    [QUESTRADE]: {
    },
    [WEALTHSIMPLE]: {
    },
  },
};

export const autoInvestSlice = createSlice({
  name: 'autoInvest',
  initialState,
  reducers: {
    setSnackbar: (state, action) => {
      state.snackbar = action.payload;
    },
    setBrokerAuthLoading: (state, action) => {
      state.auth[action.payload].isLoading = true;
    },
    setBrokerAuthLogin: (state, action) => {
      state.auth[action.payload] = {
        isLoading: false,
        isLogged: true,
      };
    },
    setBrokerAuthLogout: (state, action) => {
      state.auth[action.payload] = {
        isLoading: false,
        isLogged: false,
      };
    },
    setBeta: (state) => {
      state.beta = true;
    },
    setBrokerAccounts: (state, { payload }) => {
      state.brokerAccounts[payload.brokerName] = payload.accounts;
    },
    setBrokerBalances: (state, { payload }) => {
      state.brokerBalances[payload.brokerName] = payload.accounts;
    },
  },
});
export const selectSnackbar = (state) => state.autoInvest.snackbar;
export const selectAuth = (state) => state.autoInvest.auth;
// export const selectBeta = (state) => state.autoInvest.beta;
export const selectBrokersAccounts = (state) => state.autoInvest.brokerAccounts;
export const selectBrokersBalances = (state) => state.autoInvest.brokerBalances;
export const selectBrokerAccounts = (broker) => (state) => state.autoInvest.brokerAccounts[broker];
export const selectBrokerAccountBalances = (broker, account) => (state) => {
  const brokersBalances = state.autoInvest.brokerBalances;
  const brokerBalancesAccount = brokersBalances?.[broker]?.[account] || {};
  return brokerBalancesAccount;
};
export const selectBrokerAccountsBalances = (broker) => (state) => {
  const brokersBalances = state.autoInvest.brokerBalances;
  const brokerBalancesAccount = (brokersBalances[broker]) || {};
  return brokerBalancesAccount;
};
// Action creators are generated for each case reducer function
export const {
  setSnackbar, setBrokerAuthLoading, setBrokerAuthLogin,
  setBrokerAuthLogout, setBeta, setBrokerAccounts, setBrokerBalances,
} = autoInvestSlice.actions;

export const getBrokerAccounts = (brokerName) => async (dispatch) => {
  // dispatch(setBrokerAccountsLoading(brokerName)); todo: set field to loading
  const response = await fetchBrokerAccounts(QUESTRADE);
  if (response) {
    if (response instanceof Error) {
      console.error(brokerName, 'accounts', response.message);
    } else {
      dispatch(setBrokerAccounts({
        brokerName,
        accounts: response,
      }));
    }
  } else if (parseInt(process.env.REACT_APP_DEBUG || '0', 10)) {
    dispatch(setSnackbar({
      open: true,
      severity: 'error',
      message: 'Could not fetch Broker Accounts',
    }));
  }
};

export const getBrokerBalances = (brokerName) => async (dispatch) => {
  // dispatch(setBrokerAccountsLoading(brokerName)); todo: set field to loading
  const response = await fetchBrokerBalances(brokerName);
  if (response) {
    if (response instanceof Error) {
      console.error(brokerName, 'accounts', response.message);
    } else {
      dispatch(setBrokerBalances({
        brokerName,
        accounts: response,
      }));
    }
  } else if (parseInt(process.env.REACT_APP_DEBUG || '0', 10)) {
    dispatch(setSnackbar({
      open: true,
      severity: 'error',
      message: 'Could not fetch Broker Accounts',
    }));
  }
};

export const getBrokerLoginStatus = (brokerName) => async (dispatch) => {
  dispatch(setBrokerAuthLoading(brokerName));

  const response = await fetchBrokerLoginStatus(brokerName);
  if (response) {
    if (response.isLoggedIn) {
      dispatch(setBrokerAuthLogin(brokerName));
      if (brokerName === QUESTRADE) {
        dispatch(getBrokerAccounts(QUESTRADE));
      }
      dispatch(getBrokerBalances(brokerName));
    } else {
      dispatch(setBrokerAuthLogout(brokerName));
    }
  } else if (parseInt(process.env.REACT_APP_DEBUG || '0', 10)) {
    dispatch(setSnackbar({
      open: true,
      severity: 'error',
      message: 'could not work',
    }));
  }
};

export const submitBrokerLogout = (brokerName) => (dispatch, getState) => {
  const { uid } = getState().firebase.auth;
  const response = fetchBrokerLogout(uid, brokerName);
  if (response) {
    if (response instanceof Error) {
      dispatch(setSnackbar({
        open: true,
        severity: SNACKBAR_ERROR,
        message: response.message,
      }));
    } else {
      dispatch(setBrokerAuthLogout(brokerName));
    }
  } else if (parseInt(process.env.REACT_APP_DEBUG || '0', 10)) {
    dispatch(setSnackbar({
      open: true,
      severity: 'error',
      message: 'could not work',
    }));
  }
};

export const submitOrder = (id) => async (dispatch) => {
  const response = await sendSubmitOrder(id);
  if (response) {
    if (response instanceof Error) {
      dispatch(setSnackbar({
        open: true,
        severity: SNACKBAR_ERROR,
        message: response.message,
      }));
      return 'finished';
    }
    dispatch(setSnackbar({
      open: true,
      severity: SNACKBAR_SUCCESS,
      message: 'Order submitted successfuly',
    }));
    return 'finished';
  } if (parseInt(process.env.REACT_APP_DEBUG || '0', 10)) {
    dispatch(setSnackbar({
      open: true,
      severity: 'error',
      message: 'could not work',
    }));
  }
  return 'finished';
};

export default autoInvestSlice.reducer;
