import React, { useEffect, forwardRef } from 'react';
import { getFirestore } from 'firebase/firestore';
import 'firebase/compat/auth';
import 'firebase/compat/functions';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import AOS from 'aos';
import {
  Routes,
  Route,
  Navigate,
  useLocation,
} from 'react-router-dom';
import { getAnalytics } from 'firebase/analytics';
import { useSelector, useDispatch } from 'react-redux';
import { isLoaded, isEmpty } from 'react-redux-firebase';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import {
  getAuth, signOut, signInWithRedirect, GoogleAuthProvider,
} from 'firebase/auth';
import { useTheme } from '@mui/material/styles';
import NotFound from './features/Common/NotFound/NotFound';
import Login from './features/AutoInvest/Login/Login';
import TransactionsActivity from './features/AutoInvest/TransactionsActivity/TransactionsActivity';
import BrokersLogin from './features/AutoInvest/BrokersLogin/BrokersLogin';
import {
  getBrokerLoginStatus, selectAuth, selectSnackbar, setBeta, setSnackbar,
} from './features/AutoInvest/AutoInvestSlice';
import Home from './features/FrontPage/Home/Home';
import NavigationBar from './features/Common/NavigationBar/NavigationBar';
import SplashScreen from './features/Common/SplashScreen/SplashScreen';
import Page from './Page';

import 'react-lazy-load-image-component/src/effects/blur.css';
import 'aos/dist/aos.css';
import WSLogin from './features/AutoInvest/BrokersLogin/WSLogin/WSLogin';
import { QUESTRADE, WEALTHSIMPLE } from './features/AutoInvest/constants';
import { isBetaFlagEnabled } from './features/AutoInvest/Utils/Utils';
import Features from './features/FrontPage/Features/Features';
import About from './features/FrontPage/About/About';
import Contact from './features/FrontPage/Contact/Contact';
import PrivacyPolicy from './features/FrontPage/Disclaimers/PrivacyPolicy';
import Terms from './features/FrontPage/Disclaimers/Terms';
import Accounts from './features/AutoInvest/Accounts/Accounts';
import InvestStrategy from './features/AutoInvest/InvestStrategy/InvestStrategy';

const providedAuth = getAuth();
const provider = new GoogleAuthProvider();

getAnalytics();

const db = getFirestore();

const AppWrapper = styled.div`
  text-align: center;
  background: ${(props) => props.theme.palette.background.default};
  display: flex;
  flex-direction: column;
  height: 100vh;
  width: 100vw;
`;

const Alert = forwardRef((props, ref) => <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />);

const RequireNoAuth = ({ children }) => {
  const location = useLocation();
  const auth = useSelector((state) => state.firebase.auth);
  if (isLoaded(auth) && !isEmpty(auth)) {
    return <Navigate to="/" state={{ from: location }} replace />;
  }
  return children;
};

RequireNoAuth.propTypes = {
  children: PropTypes.element.isRequired,
};

const RequireAuth = ({ children, homeRedirect }) => {
  const location = useLocation();
  const auth = useSelector((state) => state.firebase.auth);
  if (!isLoaded(auth) || isEmpty(auth)) {
    return <Navigate to={homeRedirect ? '/home' : '/login'} state={{ from: location }} replace />;
  }
  return children;
};

RequireAuth.defaultProps = {
  homeRedirect: false,
};

RequireAuth.propTypes = {
  children: PropTypes.element.isRequired,
  homeRedirect: PropTypes.bool,
};

const AuthIsLoaded = ({ children }) => {
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.firebase.auth);
  const brokerAuth = useSelector(selectAuth);
  useEffect(() => {
    if (isLoaded(auth) && !isEmpty(auth)) {
      if (!brokerAuth[WEALTHSIMPLE].isLoading && !brokerAuth[WEALTHSIMPLE].isLogged) {
        dispatch(getBrokerLoginStatus(WEALTHSIMPLE));
      }
      if (!brokerAuth[QUESTRADE].isLoading && !brokerAuth[QUESTRADE].isLogged) {
        dispatch(getBrokerLoginStatus(QUESTRADE));
      }
    }
  }, [auth]);

  if (!isLoaded(auth)) {
    return <SplashScreen />;
  }

  return children;
};

AuthIsLoaded.propTypes = {
  children: PropTypes.node.isRequired,
};

const App = () => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const snackbar = useSelector(selectSnackbar);

  if (isBetaFlagEnabled()) {
    dispatch(setBeta());
  }
  const closeSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    dispatch(setSnackbar({
      ...snackbar,
      open: false,
    }));
  };

  useEffect(() => {
    // Remove the server-side injected CSS.
    const jssStyles = document.querySelector('#jss-server-side');
    if (jssStyles) {
      jssStyles.parentElement.removeChild(jssStyles);
    }

    AOS.init({
      once: true,
      delay: 0,
      duration: 800,
      offset: 0,
      easing: 'ease-in-out',
    });
  }, []);

  return (
    <Page>
      <AppWrapper theme={theme}>
        <NavigationBar signOut={() => signOut(providedAuth)} />
        <AuthIsLoaded>
          <Routes>
            <Route path="/login" element={<RequireNoAuth><Login signInWithGoogle={() => signInWithRedirect(providedAuth, provider)} providedAuth={providedAuth} /></RequireNoAuth>} />
            <Route path="/login/wealthsimple" element={<RequireAuth><WSLogin /></RequireAuth>} />
            <Route path="/home" element={<RequireNoAuth><Home signInWithGoogle={() => signInWithRedirect(providedAuth, provider)} providedAuth={providedAuth} /></RequireNoAuth>} />
            <Route path="/how-it-works" element={<RequireNoAuth><Features /></RequireNoAuth>} />
            <Route path="/about" element={<RequireNoAuth><About /></RequireNoAuth>} />
            <Route path="/privacy-policy" element={<RequireNoAuth><PrivacyPolicy /></RequireNoAuth>} />
            <Route path="/terms" element={<RequireNoAuth><Terms /></RequireNoAuth>} />
            <Route path="/contact-us" element={<RequireNoAuth><Contact db={db} /></RequireNoAuth>} />
            <Route path="/strategies" element={<RequireAuth><Accounts db={db} /></RequireAuth>} />
            <Route path="/" element={<RequireAuth homeRedirect><Accounts db={db} /></RequireAuth>} />
            <Route path="/activity" element={<RequireAuth><TransactionsActivity /></RequireAuth>} />
            <Route path="/settings" element={<RequireAuth><BrokersLogin db={db} /></RequireAuth>} />
            <Route path="/settings/questrade" element={<RequireAuth><BrokersLogin db={db} questradeCallback /></RequireAuth>} />
            <Route path="/strategies/:broker/:accountId/strategies/new" element={<RequireAuth><InvestStrategy db={db} accounted /></RequireAuth>} />
            <Route path="/strategies/new" element={<RequireAuth><InvestStrategy db={db} /></RequireAuth>} />
            <Route path="/strategies/:strategyId" element={<RequireAuth><InvestStrategy editMode db={db} /></RequireAuth>} />
            <Route path="*" element={<NotFound />} />
          </Routes>
        </AuthIsLoaded>
        <Snackbar open={snackbar.open} autoHideDuration={6000} onClose={closeSnackbar}>
          <Alert onClose={closeSnackbar} severity={snackbar.severity} sx={{ width: '100%' }}>
            {snackbar.message}
          </Alert>
        </Snackbar>
      </AppWrapper>
    </Page>

  );
};

export default App;
