import { getFunctions, connectFunctionsEmulator, httpsCallable } from 'firebase/functions';
import {
  getFirestore, doc, updateDoc, deleteField,
} from 'firebase/firestore';
import { getApp } from 'firebase/app';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/functions';
import firebaseConfig from '../../config/firebaseConfig';

if (!firebase.apps.length) {
  firebase.initializeApp(firebaseConfig);
}

const functions = getFunctions(getApp());
if (process.env.NODE_ENV === 'development') {
  connectFunctionsEmulator(functions, 'localhost', 5001);
}
const db = getFirestore();

export const fetchBrokerLoginStatus = async (brokerName) => {
  try {
    const submitLoginCall = httpsCallable(functions, 'isBrokerLoggedIn');
    const submitLoginCallresponse = await submitLoginCall({
      brokerName,
    });
    return submitLoginCallresponse.data;
  } catch (e) {
    console.error(e);
    return false;
  }
};

export const fetchWSLogin = async (email, password, otp) => {
  try {
    const submitLoginCall = httpsCallable(functions, 'login');
    await submitLoginCall({
      email,
      password,
      otp,
    });
    return true;
  } catch (e) {
    console.error(e);
    return e;
  }
};

export const fetchBrokerLogout = async (uid, brokerName) => {
  try {
    await updateDoc(doc(db, 'tokens', uid), {
      [brokerName]: deleteField(),
    });
    return true;
  } catch (e) {
    console.error(e);
    return e;
  }
};

export const sendSubmitOrder = async (id) => {
  try {
    const submitOrderCall = httpsCallable(functions, 'submitOrder');
    const submitOrderCallresponse = await submitOrderCall({
      strategyID: id,
    });
    return submitOrderCallresponse.data;
  } catch (e) {
    console.error(e);
    return e;
  }
};

export const getSecurityInfo = async (ticker, broker) => {
  try {
    const getSecurityInfoCall = httpsCallable(functions, 'getSecurityInfo');
    const getSecurityInfoCallresponse = await getSecurityInfoCall({
      ticker,
      broker,
    });
    return getSecurityInfoCallresponse.data.securityInfo;
  } catch (e) {
    console.error(e);
    return e;
  }
};

export const validateSecurities = async (strategyID) => {
  try {
    const areSecuritiesValid = httpsCallable(functions, 'validateStrategySecurities');
    const validateStrategySecuritiesResponse = await areSecuritiesValid({
      strategyID,
    });
    return validateStrategySecuritiesResponse.data;
  } catch (e) {
    console.error(e);
    return e;
  }
};

export const fetchBrokerAccounts = async (brokerName) => {
  try {
    const brokerAccountsCall = httpsCallable(functions, 'getBrokerAccounts');
    const brokerAccountsCallresponse = await brokerAccountsCall({
      brokerName,
    });
    return brokerAccountsCallresponse.data;
  } catch (e) {
    console.error(e);
    return e;
  }
};

export const fetchBrokerBalances = async (brokerName) => {
  try {
    const brokerAccountsBalances = httpsCallable(functions, 'getBrokerBalances');
    const brokerAccountsBalancesresponse = await brokerAccountsBalances({
      brokerName,
    });
    return brokerAccountsBalancesresponse.data;
  } catch (e) {
    console.error(e);
    return e;
  }
};

export const fetchSecurityAutocomplete = async (input) => {
  try {
    const brokerAccountsCall = httpsCallable(functions, 'fetchSecurityAutocomplete');
    const brokerAccountsCallresponse = await brokerAccountsCall({
      input,
    });
    return brokerAccountsCallresponse.data.quotes;
  } catch (e) {
    console.error(e);
    return e;
  }
};
