import React from 'react';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';

import { Link as RouterLink } from 'react-router-dom';
import Typography from '@mui/material/Typography';
// import logo from './logo.svg';
import styled from 'styled-components';
import logo from './wlLogo.png';

const LogoImg = styled.img`
margin-right: 12px;
height: 28px;
`;

const Footer = () => (
  <Grid container spacing={2}>
    <Grid item xs={12}>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        width={1}
        flexDirection={{ xs: 'column', sm: 'row' }}
      >
        <Box display="flex" alignItems="center" color="primary.dark">
          {/* <Box id="logo" sx={{ flexGrow: 0, display: 'flex' }}> */}
          <LogoImg src={logo} alt="logo" />
          {/* </Box> */}
        </Box>
        <Box display="flex" alignItems="center">
          <Box marginLeft={2}>
            <Link
              variant="body2"
              underline="none"
              component={RouterLink}
              to="/how-it-works"
              color="text.primary"
            >
              How it works
            </Link>
          </Box>
          <Box marginLeft={2}>
            <Link
              variant="body2"
              underline="none"
              component={RouterLink}
              to="/about"
              color="text.primary"
            >
              About
            </Link>
          </Box>
          <Box marginLeft={2}>
            <Link
              variant="body2"
              underline="none"
              component={RouterLink}
              to="/contact-us"
              color="text.primary"
            >
              Contact us
            </Link>
          </Box>
          <Box marginLeft={2}>
            <Link
              variant="body2"
              underline="none"
              component={RouterLink}
              to="/terms"
              color="text.primary"
            >
              Terms of Service
            </Link>
          </Box>
          <Box marginLeft={2}>
            <Link
              variant="body2"
              underline="none"
              component={RouterLink}
              to="/privacy-policy"
              color="text.primary"
            >
              Privacy Policy
            </Link>
          </Box>
        </Box>
      </Box>
    </Grid>
    <Grid item xs={12}>
      <Typography
        align="center"
        variant="subtitle2"
        color="text.secondary"
        gutterBottom
      >
        &copy; Wealthlucid. 2022. All rights reserved
      </Typography>
      <Typography
        align="center"
        variant="caption"
        color="text.secondary"
        component="p"
      >
        When you visit or interact with our sites, services or tools, we or
        our authorised service providers may use cookies for storing
        information to help provide you with a better, faster and safer
        experience.
      </Typography>
    </Grid>
  </Grid>
);

export default Footer;
