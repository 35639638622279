import React from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import LogoQuestrade from './LogoQuestrade.svg';
import LogoWealthsimple2 from './LogoWealthsimple.svg';

const mock = [
  LogoWealthsimple2,
  LogoQuestrade,
];

const BrokersLogoGrid = () => {
  const theme = useTheme();
  return (
    <Box>
      <Box marginBottom={4} justifyContent="center">
        <Typography
          gutterBottom
          variant="h4"
          fontWeight={700}
        >
          Currently supported brokers
        </Typography>
        <Typography color="text.secondary" align="center" variant="h6" sx={{ maxWidth: 680, textAlign: 'center', margin: 'auto' }}>
          Automated investments are currently available with these brokers.
          We will be adding more in the near future. Keep an eye out!
        </Typography>
      </Box>
      <Box display="flex" flexWrap="wrap" justifyContent="center">
        {mock.map((item) => (
          <Box maxWidth={140} marginTop={2} marginLeft={1} marginRight={1} key={item}>
            <Box
              component="img"
              height={1}
              width={1}
              src={item}
              alt="..."
              sx={{
                filter:
                    theme.palette.mode === 'dark'
                      ? 'brightness(0) invert(0.7)'
                      : 'none',
              }}
            />
          </Box>
        ))}
      </Box>
    </Box>
  );
};

export default BrokersLogoGrid;
