import React from 'react';
import styled from 'styled-components';
import { DataGrid } from '@mui/x-data-grid';
import { Typography } from '@mui/material';
import {
  MoneyFormat,
  accountBalancesProps, accountBalancesToChartData, fixNb,
} from '../../Utils/Utils';
import AccountPieChart from './AccountPieChart';
import { useMobileDevice } from '../../../Common/responsiveUIHelper';

const AccountDetailsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  margin-top: 16px;
  width: ${({ isMobileDevice }) => (isMobileDevice ? '100%' : '275px')};
  margin-right: 16px;
`;

const AccountSummary = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin-bottom: 15px;
  margin-left: 15px;
`;

// const AssetWrapper = styled.div`
//   display: grid;
//   grid-template-columns: 7fr 3fr 5fr;
// `;

// const UpperRow = styled(Typography)`
//   /* font-weight: 700 !important; */
//   color: #03314B;
//   text-align: start;
// `;

// const LowerRow = styled(Typography)`
//   padding-right: 8px;
// `;
const renderCellFunction = (params) => <Typography variant="body2" style={{ whiteSpace: 'normal' }}>{params.formattedValue}</Typography>;

const columns = [
  {
    field: 'symbol',
    type: 'string',
    headerName: 'Security',
    minWidth: 50,
    flex: 1,
    renderCell: renderCellFunction,
  },
  {
    field: 'price',
    type: 'string',
    headerName: 'Value',
    minWidth: 30,
    valueFormatter: ({ value }) => `${value}$`,
    renderCell: renderCellFunction,
  },
];

const AccountDetails = ({ accountBalances }) => {
  const isMobileDevice = useMobileDevice();
  const { balance, positions } = accountBalances;
  const pieData = accountBalancesToChartData(accountBalances);
  return (
    <AccountDetailsWrapper isMobileDevice={isMobileDevice}>
      <AccountSummary>
        {pieData.length > 0 && <AccountPieChart data={pieData} />}
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <Typography variant="body1" sx={{ display: 'flex' }}>
            Total Value
          </Typography>
          <MoneyFormat
            customComponent={Typography}
            variant="body1"
            value={fixNb(positions
              ?.reduce((partialSum, a) => partialSum + a.price, balance) || 0)}
            style={{ color: 'green' }}
          />
          <Typography variant="body1" sx={{ display: 'flex', mt: 1 }}>
            Cash
          </Typography>
          <MoneyFormat
            customComponent={Typography}
            variant="body1"
            value={balance || 0}
            style={{ color: 'green' }}
          />
        </div>
      </AccountSummary>
      {!isMobileDevice
        && (
        <DataGrid
          sx={{ mt: 1, mb: 1 }}
          rows={positions || []}
          columns={columns}
          initialState={{
            sorting: {
              sortModel: [{ field: 'price', sort: 'desc' }],
            },
          }}
          getRowId={({ symbol, price }) => symbol + price}
          disableSelectionOnClick
          disableColumnSelector
          disableDensitySelector
          disableColumnFilter
          hideFooter
          autoheight
          rowHeight={36}
          columnHeaderHeight={40}
          experimentalFeatures={{ newEditingApi: true }}
        />
        )}
      {/* {positions?.map(({ symbol, price }) => (
        // `${position.symbol} ${position.price}$`
        // <AssetWrapper key={symbol}>
        //   <UpperRow variant="body1">{symbol}</UpperRow>
        //   <MoneyFormat variant="h6" customComponent={UpperRow} value={price} />
        // </AssetWrapper>
      ))} */}
    </AccountDetailsWrapper>
  );
};

AccountDetails.defaultProps = {
  accountBalances: {
    balance: 0,
    positions: [],
  },
};

AccountDetails.propTypes = {
  accountBalances: accountBalancesProps,
};

export default AccountDetails;
