import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import Button from '@mui/material/Button';
import { connect, useSelector, useDispatch } from 'react-redux';
import styled from 'styled-components';
import {
  Card, Typography, useTheme, Divider,
} from '@mui/material';
import { useNavigate, useLocation } from 'react-router-dom';
import { doc, serverTimestamp, setDoc } from 'firebase/firestore';
import { isEmpty, isLoaded } from 'react-redux-firebase';
import {
  getBrokerAccounts,
  selectAuth,
  setBrokerAuthLoading, setBrokerAuthLogin, submitBrokerLogout,
} from '../AutoInvestSlice';
import { useMobileDevice } from '../../Common/responsiveUIHelper';
import BrokerLogin from './BrokerLogin';
import { QUESTRADE, QUESTRADE_LOGIN_URL, WEALTHSIMPLE } from '../constants';

const BrokersLoginWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: ${({ isMobileDevice }) => (isMobileDevice ? 'calc(100% - 168px)' : '100%')};
  align-items: center;
  justify-content: center;
  overflow-y: ${({ isMobileDevice }) => (isMobileDevice ? 'auto' : 'inherit')};
  background-color: ${(props) => props.theme.palette.background.default};
  `;

const LoginCard = styled(Card)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: ${({ $isMobileDevice }) => ($isMobileDevice ? 'center' : 'space-evenly')};
  padding: 15px;
  max-width: 496px;
  min-width: ${({ $isMobileDevice }) => ($isMobileDevice ? '100%' : '496px')};
  min-height: ${({ $isMobileDevice }) => ($isMobileDevice ? '100%' : '300px')};
`;

const getQuestradeRedirect = () => {
  let redirectURL = `${QUESTRADE_LOGIN_URL + window.location.origin.replace('www.', '')}/settings/questrade/`;
  if (parseInt(process.env.REACT_APP_DEBUG || '0', 10)) {
    redirectURL = `${QUESTRADE_LOGIN_URL}https://tolocalhost.com/settings/questrade/`;
  }
  return encodeURI(redirectURL);
};

const BrokersLogin = ({ questradeCallback, db, auth }) => {
  const authSelector = useSelector(selectAuth);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const theme = useTheme();
  const { hash } = useLocation();
  const isMobileDevice = useMobileDevice();

  const handleQTLogin = async () => {
    const params = hash.split('&').map((item) => item.substring(item.indexOf('=') + 1));
    await dispatch(setBrokerAuthLoading(QUESTRADE));
    await setDoc(doc(db, 'tokens', auth.uid), {
      questrade: {
        access: params[0],
        refresh: params[1],
        expires: params[3],
        created: serverTimestamp(),
      },
      updated: serverTimestamp(),
    }, { merge: true });
    await dispatch(setBrokerAuthLogin(QUESTRADE));
  };

  useEffect(() => {
    if (questradeCallback) {
      handleQTLogin();
    }
  }, []);

  useEffect(() => {
    if (questradeCallback) {
      dispatch(getBrokerAccounts(QUESTRADE));
    }
  }, [isLoaded(auth) && !isEmpty(auth)]);

  return (
    <BrokersLoginWrapper theme={theme} isMobileDevice={isMobileDevice}>
      <LoginCard $isMobileDevice={isMobileDevice}>
        <Typography variant={isMobileDevice ? 'h5' : 'h4'} gutterBottom component="div">
          Brokers
        </Typography>
        <BrokerLogin
          brokerName={QUESTRADE}
          brokerAuth={authSelector[QUESTRADE]}
          submitLogout={() => dispatch(submitBrokerLogout(QUESTRADE))}
          externalURL
          loginDestination={getQuestradeRedirect()}
        />
        <BrokerLogin
          brokerName={WEALTHSIMPLE}
          brokerAuth={authSelector[WEALTHSIMPLE]}
          submitLogout={() => dispatch(submitBrokerLogout(WEALTHSIMPLE))}
          loginDestination="/login/wealthsimple"
        />
        <Divider style={{ width: '100%', marginTop: 12, marginBottom: 12 }} />
        <Typography variant="body1" component="div" style={{ paddingTop: '12px' }}>After linking a broker account</Typography>
        <Typography variant="body1" gutterBottom component="div" style={{ paddingBottom: '12px' }}>Add your first automated investing strategy</Typography>
        <Button variant="outlined" type="submit" value="Submit" onClick={() => navigate('/strategies/new')} sx={{ m: 1, width: '19ch' }}>Add Strategy</Button>
      </LoginCard>
    </BrokersLoginWrapper>
  );
};

BrokersLogin.defaultProps = {
  questradeCallback: false,
};

BrokersLogin.propTypes = {
  questradeCallback: PropTypes.bool,
  db: PropTypes.shape({
    collection: PropTypes.func,
  }).isRequired,
  auth: PropTypes.shape({
    uid: PropTypes.string.isRequired,
  }).isRequired,
};

const enhance = connect(
  // Map redux state to component props
  ({ firebase: { auth } }) => ({
    auth,
  }),
);

export default enhance(BrokersLogin);
