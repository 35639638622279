import React from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import styled from 'styled-components';
import Container from '../Container';
import Hero from './Hero/Hero';
import Services from './Services/Services';
import BrokersLogoGrid from './BrokersLogoGrid/BrokersLogoGrid';
import CallToAction from './CallToAction/CallToAction';
import Security from './Security/Security';
import Footer from '../Footer/Footer';

const Home = () => {
  const theme = useTheme();

  const HomeWrapper = styled.div`
  /* display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  height: 100%;
  width: 100%; */
  overflow-y: auto;
  background-color: ${theme.palette.background.default};
`;

  return (
    <HomeWrapper>
      <Box
        position="relative"
        sx={{
          backgroundColor: theme.palette.alternate.main,
          marginTop: -13,
          paddingTop: 13,
        }}
      >
        <Container>
          <Hero />
        </Container>
        <Box
          component="svg"
          preserveAspectRatio="none"
          xmlns="http://www.w3.org/2000/svg"
          x="0px"
          y="0px"
          viewBox="0 0 1920 100.1"
          sx={{
            width: '100%',
            marginBottom: theme.spacing(-1),
          }}
        >
          <path
            fill={theme.palette.background.paper}
            d="M0,0c0,0,934.4,93.4,1920,0v100.1H0L0,0z"
          />
        </Box>
      </Box>
      <Container paddingTop="0 !important">
        <Services />
      </Container>
      <Container paddingTop="0 !important">
        <BrokersLogoGrid />
      </Container>
      <Box
        position="relative"
        sx={{
          backgroundColor: theme.palette.alternate.main,
        }}
      >
        <Box>
          <Container paddingBottom="0 !important">
            <Security />
          </Container>
          <Container paddingTop="0 !important">
            <CallToAction />
          </Container>
        </Box>
        <Box
          component="svg"
          preserveAspectRatio="none"
          xmlns="http://www.w3.org/2000/svg"
          x="0px"
          y="0px"
          viewBox="0 0 1920 100.1"
          sx={{
            width: '100%',
            marginBottom: theme.spacing(-1),
          }}
        >
          <path
            fill={theme.palette.background.paper}
            d="M0,0c0,0,934.4,93.4,1920,0v100.1H0L0,0z"
          />
        </Box>
      </Box>
      <Container paddingY={2}>
        <Footer />
      </Container>
    </HomeWrapper>
  );
};

export default Home;
