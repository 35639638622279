import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { StateInspector } from 'reinspect';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import { getAnalytics, logEvent } from 'firebase/analytics';
import { Provider } from 'react-redux';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import 'firebase/functions';
import { createFirestoreInstance } from 'redux-firestore';
import { ReactReduxFirebaseProvider } from 'react-redux-firebase';
import { store } from './features/AutoInvest/store';
import reportWebVitals from './reportWebVitals';
import App from './App';
import firebaseConfig from './config/firebaseConfig';

const rrfConfig = {
  userProfile: 'users',
  useFirestoreForProfile: true, // Firestore for Profile instead of Realtime DB
  // enableClaims: true // Get custom claims along with the profile
};

const app = firebase.initializeApp(firebaseConfig);
firebase.firestore();
const analytics = getAnalytics(app);

Sentry.init({
  dsn: 'https://f013a01d3a7d4e2dbc3b766caa496983@o1118351.ingest.sentry.io/6152225',
  integrations: [new Integrations.BrowserTracing()],
  tracesSampleRate: 1.0,
});

const rrfProps = {
  firebase,
  config: rrfConfig,
  dispatch: store.dispatch,
  createFirestoreInstance,
};

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <ReactReduxFirebaseProvider {...rrfProps}>
        <StateInspector name="Stock Swing">
          <BrowserRouter>
            <App />
          </BrowserRouter>
        </StateInspector>
      </ReactReduxFirebaseProvider>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root'),
);

const sendToAnalytics = function sendToAnalytics({ id, name, value }) {
  logEvent(analytics, name, {
    event_category: 'Web Vitals',
    value: Math.round(name === 'CLS' ? value * 1000 : value), // values must be integers
    event_label: id, // id unique to current page load
    non_interaction: true, // avoids affecting bounce rate
  });
};
reportWebVitals(sendToAnalytics);
