import { Box, useTheme } from '@mui/material';
import React from 'react';
import Container from '../Container';
import Footer from '../Footer/Footer';

const Terms = () => {
  const theme = useTheme();
  return (
    <Box sx={{ background: theme.palette.background.paper, overflowY: 'auto' }}>
      <Box
        sx={{
          marginTop: -13,
          paddingTop: 13,
        }}
      >
        <Container textAlign="left">
          <h1>Terms of Service</h1>
          <p>Last updated: March 5, 2023</p>
          <h1>Interpretation and Definitions</h1>
          <h2>Interpretation</h2>
          <p>
            The words of which the initial letter is capitalized have meanings defined under the
            following conditions.
            The following definitions shall have the same meaning regardless of whether they
            appear in singular or in plural.
          </p>
          <h2>Definitions</h2>
          <p>For the purposes of this Disclaimer:</p>
          <ul>
            <li>
              <strong>Company</strong>
              {' '}
              (referred to as either &quot;the Company&quot;, &quot;We&quot;, &quot;Us&quot; or
              &quot;Our&quot; in this Disclaimer) refers to Wealthlucid.
            </li>
            <li>
              <strong>Service</strong>
              {' '}
              refers to the Website.
            </li>
            <li>
              <strong>You</strong>
              {' '}
              means the individual accessing the Service, or the company, or other legal entity on
              behalf of which such individual is accessing or using the Service, as applicable.
            </li>
            <li>
              <strong>Website</strong>
              {' '}
              refers to Wealthlucid, accessible from
              {' '}
              <a href="wealthlucid.com" rel="external nofollow noopener" target="_blank">wealthlucid.com</a>
            </li>
          </ul>
          <h1>Disclaimer</h1>
          <p>
            The information provided on this website does not, and is not intended to,
            constitute legal advice; instead, all information, content, and materials
            available on this site are for general informational purposes only
          </p>
          <p>
            The Company assumes no responsibility for errors or omissions in the contents of
            the Service.
          </p>
          <p>
            In no event shall the Company be liable for any special, direct, indirect,
            consequential, or incidental damages or any damages whatsoever, whether
            in an action of contract, negligence or other tort, arising out of or
            in connection with the use of the Service or the contents of the Service.
            The Company reserves the right to make additions, deletions, or
            modifications to the contents on the Service at any time without prior notice.
          </p>
          <p>
            The Company does not warrant that the Service is free of viruses or other
            harmful components.
          </p>
          <h1>External Links </h1>
          <p>
            The Service may contain links to external websites that are not provided or
            maintained by or in any way affiliated with the Company.
          </p>
          <p>
            Please note that the Company does not guarantee the accuracy, relevance,
            timeliness, or completeness of any information on these external websites.
          </p>
          <h1>Errors and Omissions </h1>
          <p>
            The information given by the Service is for general guidance on matters
            of interest only. Even if the Company takes every precaution to insure
            that the content of the Service is both current and accurate, errors can
            occur. Plus, given the changing nature of laws, rules and regulations,
            there may be delays, omissions or inaccuracies in the information
            contained on the Service.
          </p>
          <p>
            The Company is not responsible for any errors or omissions,
            or for the results obtained from the use of this information.
          </p>
          <h1>No Responsibility </h1>
          <p>
            The information on the Service is provided with the understanding that
            the Company is not herein engaged in rendering legal, accounting, tax,
            or other professional advice and services. As such, it should not be used
            as a substitute for consultation with professional accounting, tax,
            legal or other competent advisers.
          </p>
          <p>
            In no event shall the Company or its suppliers be liable for any special,
            incidental, indirect, or consequential damages whatsoever arising out of
            or in connection with your access or use or inability to access
            or use the Service.
          </p>
          <h1>&quot;Use at Your Own Risk&quot; </h1>
          <p>
            All information in the Service is provided &quot;as is&quot;, with no guarantee
            of completeness, accuracy, timeliness or of the results obtained from the use
            of this information, and without warranty of any kind, express or implied,
            including, but not limited to warranties of performance, merchantability
            and fitness for a particular purpose.
          </p>
          <p>
            The Company will not be liable to You or anyone else for any decision made
            or action taken in reliance on the information given by the Service or for
            any consequential, special or similar damages, even if advised of
            the possibility of such damages.
          </p>
          <h1>Recommendations </h1>
          <p>
            Our platform does not provide any recommendations or advice to buy,
            sell or hold any financial instrument, including stocks, bonds, and other securities.
          </p>
          <h1>Rebalancing </h1>
          <p>
            Our platform provides tools to assist users with account
            rebalancing. However, all rebalancing decisions and actions are solely the
            responsibility of the user.
          </p>
          <h1>Information collection</h1>
          <p>
            We do not collect your broker&apos;s credentials.
            Our system only retains the tokens generated by
            the broker in order to allow you to send orders.
          </p>
          <p>
            We do not collect sensitive personal information from you via the Website.
            Sensitive information includes data relating to:
            race or ethnic origin; political opinions; religious
            or other similar beliefs; physical or mental health;
            sexual orientation or criminal record.
          </p>
          <h1>Contact Us</h1>
          <p>If you have any questions, You can contact Us:</p>
          <ul>
            <li>By email: contact@wealthlucid.com</li>
          </ul>
        </Container>
      </Box>
      <Box bgcolor={theme.palette.background.paper}>
        <Container style={{ paddingBottom: 16 }}>
          <Footer />
        </Container>
      </Box>
    </Box>
  );
};

export default Terms;
