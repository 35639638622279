import { Box, useTheme } from '@mui/material';
import React from 'react';
import Container from '../Container';
import Footer from '../Footer/Footer';
import Content from './Content/Content';
import FAQFooter from './Footer/Footer';
import Headline from './Headline/Headline';

const About = () => {
  const theme = useTheme();
  return (
    <Box sx={{ background: theme.palette.background.paper, overflowY: 'auto' }}>
      <Box
        sx={{
          backgroundColor: theme.palette.alternate.main,
          backgroundImage: `linear-gradient(120deg, ${theme.palette.background.paper} 0%, ${theme.palette.alternate.main} 100%)`,
          marginTop: -13,
          paddingTop: 13,
        }}
      >
        <Container>
          <Headline />
        </Container>
      </Box>
      <Container maxWidth={800}>
        <Content />
      </Container>
      <Box bgcolor={theme.palette.alternate.main}>
        <Container>
          <FAQFooter />
        </Container>
      </Box>
      <Box bgcolor={theme.palette.background.paper}>
        <Container paddingY={2}>
          <Footer />
        </Container>
      </Box>
    </Box>
  );
};

export default About;
