import React, { useState } from 'react';
import IconButton from '@mui/material/IconButton';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import TextField from '@mui/material/TextField';
import LoadingButton from '@mui/lab/LoadingButton';
import { Card, Typography, useTheme } from '@mui/material';
import styled from 'styled-components';
import { Link, useNavigate } from 'react-router-dom';
import CloseIcon from '@mui/icons-material/Close';
import { useDispatch } from 'react-redux';
import { getAnalytics, logEvent } from 'firebase/analytics';
import { useMobileDevice } from '../../../Common/responsiveUIHelper';
import { setBrokerAuthLogin } from '../../AutoInvestSlice';
import { fetchWSLogin } from '../../AutoInvestAPI';
import { WEALTHSIMPLE } from '../../constants';
import { useInput } from '../../Utils/Utils';

const analytics = getAnalytics();

const WSLoginWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: ${({ isMobileDevice }) => (isMobileDevice ? 'calc(100% - 168px)' : '100%')};
  align-items: center;
  justify-content: center;
  overflow-y: ${({ isMobileDevice }) => (isMobileDevice ? 'auto' : 'inherit')};
  background-color: ${(props) => props.theme.palette.background.default};
`;

const LoginCard = styled(Card)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: ${({ isMobileDevice }) => (isMobileDevice ? 'flex-start' : 'center')};
  padding: 15px;
  max-width: 496px;
  /* height:100%; */
`;

const TitleNavigation = styled.div`
  display: flex;
  justify-content: space-between;
`;

const WSLogin = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobileDevice = useMobileDevice();
  const [passwordInput, setPasswordInput] = useState({
    password: '',
    showPassword: false,
  });
  const [formState, setFormState] = useState({
    emailError: false,
    passwordError: false,
    OTPError: false,
    OTPPhase: false,
  });
  const { value: email, bind: bindEmail, reset: resetEmail } = useInput('');
  const { value: otp, bind: bindOtp, reset: resetOtp } = useInput('');
  const [loginLoading, setloginLoading] = useState(false);

  const handlePasswordChange = (prop) => (event) => {
    setPasswordInput({ ...passwordInput, [prop]: event.target.value });
  };

  const handleClickShowPassword = () => {
    setPasswordInput({
      ...passwordInput,
      showPassword: !passwordInput.showPassword,
    });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const submitLogin = async (evt) => {
    evt.preventDefault();
    try {
      await setloginLoading(true);
      const response = await fetchWSLogin(email, passwordInput.password, formState.OTPPhase
        ? otp : null);
      setloginLoading(false);

      if (response instanceof Error) {
        if (response.message === 'email is invalid.') {
          setFormState({
            emailError: true,
            passwordError: false,
            OTPError: false,
            OTPPhase: false,
          });
        } else if (response.message === 'password is invalid.') {
          setFormState({
            emailError: false,
            passwordError: true,
            OTPError: false,
            OTPPhase: false,
          });
        } else if (response.message === 'Wrong credentials or wrong OTP!') {
          setFormState({
            emailError: !formState.OTPPhase,
            passwordError: !formState.OTPPhase,
            OTPError: !!formState.OTPPhase,
            OTPPhase: !!formState.OTPPhase,
          });
        } else if (response.message === 'Authentification successful but OTP is missing!') {
          setFormState({
            emailError: false,
            passwordError: false,
            OTPError: false,
            OTPPhase: true,
          });
        }
      } else {
        logEvent(analytics, 'WS_linked');
        dispatch(setBrokerAuthLogin(WEALTHSIMPLE));
        setFormState({
          emailError: false,
          passwordError: false,
          OTPError: false,
          OTPPhase: false,
        });
        setPasswordInput({
          password: '',
          showPassword: false,
        });
        resetEmail();
        resetOtp();
        navigate('/settings');
      }
    } catch (error) {
      setloginLoading(false);
      console.error('error', error);
    }
  };

  const getOTPHelperText = () => {
    let helperText = 'Leave this field empty until you receive your verification code';
    if (formState.OTPPhase) {
      helperText = 'You will receive your Verfication code via SMS or Email.';
    }
    if (formState.OTPError) {
      helperText = 'Verification code (OTP) is wrong';
    }
    return helperText;
  };

  return (
    <WSLoginWrapper theme={theme} isMobileDevice={isMobileDevice}>
      <LoginCard isMobileDevice={isMobileDevice}>
        <TitleNavigation>
          <Typography variant="h4" style={{ margin: 'auto' }} gutterBottom component="div">
            Wealthsimple account
          </Typography>
          <IconButton
            component={Link}
            to="/settings"
            size="large"
          >
            <CloseIcon fontSize="inherit" />
          </IconButton>
        </TitleNavigation>
        <Typography variant="subtitle2" gutterBottom component="div" sx={{ m: 1, maxWidth: '450px' }}>
          Your WealthSimple credentials are not stored.
          At any time, you may revoke your account and all traces of your
          login with Wealthsimple will be erased. Your data and money are secure.
        </Typography>
        {!formState.OTPPhase
          ? (
            <>
              <FormControl sx={{ m: 1, width: '25ch' }} variant="outlined">
                <TextField
                  id="email"
                  label="Email"
                  helperText={formState.emailError && !formState.emailError ? 'Invalid email' : null}
                  error={formState.emailError}
                  {...bindEmail}
                />
              </FormControl>
              <FormControl sx={{ m: 1, width: '25ch' }} variant="outlined" error={formState.passwordError}>
                <InputLabel htmlFor="outlined-adornment-password">Password</InputLabel>
                <OutlinedInput
                  id="password"
                  type={passwordInput.showPassword ? 'text' : 'password'}
                  value={passwordInput.password}
                  onChange={handlePasswordChange('password')}
                  endAdornment={(
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {passwordInput.showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                    )}
                  label="Password"
                />
                <FormHelperText id="password-helper-text">
                  {formState.passwordError ? 'Wrong Password or Email' : null}
                </FormHelperText>
              </FormControl>
            </>
          )
          : (
            <FormControl sx={{ m: 1, width: '25ch' }} variant="outlined">
              <TextField
                id="Verificaton code"
                label="Verificaton code"
                error={formState.OTPError}
                helperText={getOTPHelperText()}
                {...bindOtp}
              />
            </FormControl>
          )}
        <FormControl sx={{ m: 1, width: '12ch' }} variant="outlined">
          <LoadingButton variant="outlined" loading={loginLoading} onClick={submitLogin}>Submit</LoadingButton>
        </FormControl>
      </LoginCard>
    </WSLoginWrapper>
  );
};

export default WSLogin;
