import React from 'react';
import PropTypes from 'prop-types';
import IconButton from '@mui/material//IconButton';
import EditIcon from '@mui/icons-material/Edit';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import DeleteIcon from '@mui/icons-material/Delete';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import { CircularProgress, Typography } from '@mui/material';

import { LoadingButton } from '@mui/lab';
import styled from 'styled-components';
import StockOrdersList from '../../StockOrders/StockOrdersList';
import {
  RECURRENCES_TIMES, STRATEGIES, TRIGGERS,
} from '../../constants';
import { accountBalancesProps, useCurrentSecurities } from '../../Utils/Utils';

const AccountStrategyCard = styled.div`
  width: 100%;
  `;

const AccountStrategyCardDesc = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-between;
  text-align: left;
  margin-left: 15px;
  margin-top: 15px;
  `;

const AccountStrategyCardButtons = styled.div`
  display: flex;
  flex-wrap: nowrap;
  justify-content: flex-start;
  `;

const AccountStrategy = ({
  strategy,
  testStrategy,
  editStrategy,
  copyStrategy,
  removeStrategy,
  isTestStratLoading,
  setStrategyActivated,
  isStrategyActivating,
  accountBalances,
}) => {
  const {
    recurrenceFrequency, amountTrigger,
    amountFrozen, startingDate, strategyType, triggerType,
    nickname, id, autoStrategy, activated,
  } = strategy;
  const {
    currentSecuritiesTotal, currentSecuritiesPrices,
  } = useCurrentSecurities(autoStrategy, accountBalances);

  const getStrategyCardDescription = () => {
    let strategyTypeMsg = '';
    let triggerTypeMsg = '';

    if (strategyType === STRATEGIES.BUY_STOCKS) {
      strategyTypeMsg = 'Buying securities';
    } else if (strategyType === STRATEGIES.REBALANCE) {
      strategyTypeMsg = 'Rebalancing securities';
      if (Number(amountFrozen)) {
        strategyTypeMsg += ` but ${amountFrozen}$`;
      }
    }

    if (triggerType === TRIGGERS.RECURRENCE) {
      const currentDateTime = new Date().getTime();
      const dateDiff = Math.floor((currentDateTime - startingDate) / 8.64e7);
      let nextDate = new Date(startingDate).toLocaleString('en-En', { month: 'long', day: 'numeric' });

      if (dateDiff === 0) {
        nextDate = new Date().toLocaleString('en-En', { month: 'long', day: 'numeric' });
      } else if (dateDiff < 0) {
        nextDate = new Date(startingDate).toLocaleString('en-En', { month: 'long', day: 'numeric' });
      } else if (RECURRENCES_TIMES[recurrenceFrequency]) {
        const daysModulo = dateDiff % RECURRENCES_TIMES[recurrenceFrequency];
        nextDate = new Date(new Date().setDate(new Date().getDate() + daysModulo))
          .toLocaleString('en-En', { month: 'long', day: 'numeric' });
      }

      triggerTypeMsg = `${recurrenceFrequency} (${nextDate})`;
    } else if (triggerType === TRIGGERS.DEPOSIT) {
      triggerTypeMsg = `when depositing ${amountTrigger}$`;
    }

    return `${strategyTypeMsg} ${triggerTypeMsg}`;
  };

  return (
    <AccountStrategyCard key={id}>
      <AccountStrategyCardDesc>
        <div>
          <Typography variant="h6" style={{ color: '#2980B9', fontWeight: 600 }} component="div">
            {nickname}
          </Typography>
          <Typography variant="body1" component="div">
            {getStrategyCardDescription()}
          </Typography>
        </div>
        <AccountStrategyCardButtons>
          <FormGroup sx={{
            m: 1, display: 'flex', flexDirection: 'row', alignItems: 'center',
          }}
          >
            {isStrategyActivating ? (
              <CircularProgress style={{
                height: 25, width: 25, position: 'relative', left: 21, marginLeft: -25,
              }}
              />
            ) : null}
            <FormControlLabel
              style={{ marginRight: 0 }}
              control={(
                <Switch
                  checked={activated}
                  onChange={setStrategyActivated}
                />
        )}
              label="Activated"
            />
          </FormGroup>
          <LoadingButton
            variant="outlined"
            size="small"
            type="submit"
            sx={{
              width: '121.5px',
              height: '44.75px',
              alignSelf: 'center',
            }}
            value="Submit"
            loading={isTestStratLoading}
            onClick={testStrategy}
          >
            Manual Trade
          </LoadingButton>
          <IconButton onClick={editStrategy} aria-label="edit">
            <EditIcon />
          </IconButton>
          <IconButton onClick={copyStrategy} aria-label="edit">
            <ContentCopyIcon />
          </IconButton>
          <IconButton onClick={removeStrategy} aria-label="delete">
            <DeleteIcon />
          </IconButton>
        </AccountStrategyCardButtons>
      </AccountStrategyCardDesc>
      <StockOrdersList
        autoStrategy={autoStrategy}
        hiddenShadow
        rebalancing={strategyType === STRATEGIES.REBALANCE}
        currentSecuritiesPrices={currentSecuritiesPrices}
        currentSecuritiesTotal={currentSecuritiesTotal}
      />
    </AccountStrategyCard>
  );
};

AccountStrategy.defaultProps = {
  accountBalances: {},
};

AccountStrategy.propTypes = {
  accountBalances: accountBalancesProps,
  copyStrategy: PropTypes.func.isRequired,
  editStrategy: PropTypes.func.isRequired,
  isStrategyActivating: PropTypes.bool.isRequired,
  isTestStratLoading: PropTypes.bool.isRequired,
  testStrategy: PropTypes.func.isRequired,
  removeStrategy: PropTypes.func.isRequired,
  setStrategyActivated: PropTypes.func.isRequired,
  strategy: PropTypes.shape({
    activated: PropTypes.bool,
    amountFrozen: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    amountTrigger: PropTypes.string,
    autoStrategy: PropTypes.arrayOf(PropTypes.shape({
      amount: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      orderType: PropTypes.number.isRequired,
      stock: PropTypes.oneOfType([PropTypes.object, PropTypes.string]).isRequired,
    })).isRequired,
    broker: PropTypes.string,
    id: PropTypes.string,
    nickname: PropTypes.string,
    recurrenceFrequency: PropTypes.string,
    startingDate: PropTypes.number,
    strategyType: PropTypes.string,
    triggerType: PropTypes.string,
  }).isRequired,
};

export default AccountStrategy;
