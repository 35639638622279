import React from 'react';
import PropTypes from 'prop-types';
import {
  Button, Skeleton, Typography,
} from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';

const BrokerLoginWrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  justify-content: center;
  margin-bottom: 12px;
`;

const BrokerLogin = ({
  brokerName, brokerAuth, submitLogout, loginDestination, externalURL,
}) => {
  const navigate = useNavigate();
  let brokerLoginContent = (
    <Skeleton
      sx={{ marginBottom: '24px' }}
      variant="rectangular"
      width="40%"
      height={60}
    />
  );
  if (brokerAuth.isLogged && !brokerAuth.isLoading) {
    brokerLoginContent = (
      <>
        <CheckCircleIcon color="success" fontSize="large" />
        <Button
          variant="outlined"
          onClick={submitLogout}
          sx={{ m: 1, width: '20ch' }}
        >
          unlink
          {' '}
          {brokerName}
        </Button>
      </>
    );
  } else if (!brokerAuth.isLogged && !brokerAuth.isLoading) {
    brokerLoginContent = (
      <Button
        variant="contained"
        href={externalURL ? loginDestination : null}
        to={{ pathname: loginDestination }}
        onClick={!externalURL ? () => navigate(loginDestination) : null}
      >
        Link
        {' '}
        {brokerName}
      </Button>
    );
  }
  return (
    <BrokerLoginWrapper>
      <Typography variant="h6" gutterBottom style={{ marginRight: 12, textTransform: 'capitalize' }} component="div">
        {brokerName}
      </Typography>
      {brokerLoginContent}
    </BrokerLoginWrapper>
  );
};

BrokerLogin.defaultProps = {
  externalURL: false,
};

BrokerLogin.propTypes = {
  brokerAuth: PropTypes.shape({
    isLoading: PropTypes.bool.isRequired,
    isLogged: PropTypes.bool.isRequired,
  }).isRequired,
  submitLogout: PropTypes.func.isRequired,
  brokerName: PropTypes.string.isRequired,
  loginDestination: PropTypes.string.isRequired,
  externalURL: PropTypes.bool,
};

export default BrokerLogin;
