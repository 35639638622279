import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import {
  DataGrid,
  GridActionsCellItem,
} from '@mui/x-data-grid';
import { Box } from '@mui/system';
import { Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { getSecuritySymbol } from '../Utils/Utils';
import { useMobileDevice } from '../../Common/responsiveUIHelper';
import { ORDER_TYPES, STRATEGIES } from '../constants';

const InvestStrategySecuritiesUntrackedWrapper = styled.div`
  margin: ${({ isMobileDevice }) => (isMobileDevice ? '0' : '0px 8px 8px 8px')};
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  flex-wrap: wrap;
  width: 100%;
`;

const InvestStrategySecuritiesUntracked = ({
  setAutoStrategy, autoStrategy, accountBalance, strategyType,
}) => {
  const isMobileDevice = useMobileDevice();
  const [untrackedData, setUntrackedData] = useState([]);

  useEffect(() => {
    setUntrackedData(accountBalance?.positions
      ?.filter((position) => !autoStrategy.find(
        (auto) => getSecuritySymbol(auto.stock) === position.symbol,
      )) || []);
  }, [accountBalance]);

  const handleAddClick = (id, security) => () => {
    const { symbol, shortname, exchange } = security;
    setAutoStrategy([...autoStrategy, {
      id: Math.random(),
      stock: { symbol, shortname, exchange },
      amount: '',
      OrderType: strategyType === STRATEGIES.REBALANCE ? ORDER_TYPES.PERCENT_MARKET_BUY : null,
    }]);
  };

  const renderCellFunction = (params) => <Typography variant="body2" style={{ whiteSpace: 'normal' }}>{params.formattedValue}</Typography>;

  const columns = [
    {
      field: 'symbol',
      type: 'string',
      headerName: 'Ignored Securities',
      minWidth: 260,
      flex: 1,
      renderCell: renderCellFunction,
    },
    {
      field: 'price',
      type: 'string',
      headerName: 'Value',
      minWidth: 68,
      flex: 0,
      valueFormatter: ({ value }) => `${value}$`,
      renderCell: renderCellFunction,
    },
    {
      field: 'actions',
      type: 'actions',
      minWidth: 65,
      flex: 0,
      getActions: ({ id, row }) => [
        <GridActionsCellItem
          icon={<AddCircleIcon />}
          label="Edit"
          className="textPrimary"
          onClick={handleAddClick(id, row)}
          color="inherit"
        />,
      ]
      ,
    },
  ];

  const useStyles = makeStyles(() => ({
    overlayMod: { width: '100% !important', height: '100% !important' },
  }));

  const customWrapper = useStyles();

  return (
    <InvestStrategySecuritiesUntrackedWrapper isMobileDevice={isMobileDevice}>
      <Box
        sx={{
          height: 315,
          width: isMobileDevice ? '100%' : 710,
          '& .actions': {
            color: 'text.secondary',
          },
          '& .textPrimary': {
            color: 'text.primary',
          },
        }}
      >
        <DataGrid
          rows={untrackedData}
          columns={columns}
          getRowId={({ symbol, price }) => symbol + price}
          initialState={{
            sorting: {
              sortModel: [{ field: 'price', sort: 'desc' }],
            },
          }}
          disableSelectionOnClick
          disableColumnSelector
          disableDensitySelector
          disableColumnFilter
          hideFooter
          autoheight
          columnHeaderHeight={40}
          classes={{ overlayWrapperInner: customWrapper.overlayMod }}
          components={{
            noRowsOverlay: () => { <div />; },
          }}
          experimentalFeatures={{ newEditingApi: true }}
        />
      </Box>
    </InvestStrategySecuritiesUntrackedWrapper>
  );
};

InvestStrategySecuritiesUntracked.propTypes = {
  accountBalance: PropTypes.shape({
    balance: PropTypes.number,
    positions: PropTypes.arrayOf(PropTypes.shape({
      price: PropTypes.number,
      symbol: PropTypes.string,
    })),
  }).isRequired,
  autoStrategy: PropTypes.arrayOf(PropTypes.shape({
    Stock: PropTypes.string,
    Market: PropTypes.string,
    OrderType: PropTypes.number,
    Amount: PropTypes.number,
  })).isRequired,
  setAutoStrategy: PropTypes.func.isRequired,
  strategyType: PropTypes.string.isRequired,
};

export default InvestStrategySecuritiesUntracked;
