import { responsiveFontSizes } from '@mui/material';
import { createTheme } from '@mui/material/styles';
import shadows from './shadows';
import { light, dark } from './palette';

const getTheme = (mode, themeToggler) => responsiveFontSizes(
  createTheme({
    palette: mode === 'dark' ? dark : light,
    shadows: shadows(mode),
    typography: {
      h5: {
        color: '#2980B9',
        fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
        fontWeight: 600,
        fontSize: '1.5rem',
        lineHeight: 1.334,
        letterSpacing: '0em',
      },
    },
    zIndex: {
      appBar: 1200,
      drawer: 1300,
    },
    components: {
      MuiButton: {
        styleOverrides: {
          root: {
            fontWeight: 400,
            borderRadius: 5,
            paddingTop: 10,
            paddingBottom: 10,
          },
          containedSecondary: mode === 'light' ? { color: 'white' } : {},
        },
      },
      MuiInputBase: {
        styleOverrides: {
          root: {
            borderRadius: 5,
          },
        },
      },
      MuiOutlinedInput: {
        styleOverrides: {
          root: {
            borderRadius: 5,
          },
          input: {
            borderRadius: 5,
          },
        },
      },
      MuiCard: {
        styleOverrides: {
          root: {
            borderRadius: 8,
          },
        },
      },
    },
    themeToggler,
  }),
);

// const theme = createTheme({
//   palette: {
//     primary: {
//       main: '#2980B9',
//     },
//     background: {
//       default: '#fafafa',
//     },
//   },
//   typography: {
//     h4: {
//       color: '#2980B9',
//       fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
//       fontWeight: 600,
//       fontSize: '2.125rem',
//       lineHeight: 1.235,
//       letterSpacing: '0.00735em',
//     },
//     h5: {
//       color: '#2980B9',
//       fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
//       fontWeight: 600,
//       fontSize: '1.5rem',
//       lineHeight: 1.334,
//       letterSpacing: '0em',
//     },
//     h6: {
//       color: '#2980B9',
//       fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
//       fontWeight: 600,
//       fontSize: '1.25rem',
//       lineHeight: 1.6,
//       letterSpacing: '0.0075em',
//     },
//   },
// });

export default getTheme;
