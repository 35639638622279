import PropTypes from 'prop-types';
import React, {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import parse from 'autosuggest-highlight/parse';
import match from 'autosuggest-highlight/match';
import { debounce } from '@mui/material/utils';
import { useGridApiContext } from '@mui/x-data-grid';
import { fetchSecurityAutocomplete } from '../AutoInvestAPI';

const SecuritiesAutoComplete = ({ id, field, value }) => {
  const apiRef = useGridApiContext();
  const [inputValue, setInputValue] = useState('');
  const [options, setOptions] = useState([]);
  const loading = () => !!(inputValue.length && !options.length);

  const handleChange = useCallback((event, newValue) => {
    event.stopPropagation();
    apiRef.current.setEditCellValue({ id, field, value: newValue });
  }, [id, field]);

  const getValue = useCallback(() => {
    if (value) { return value; }

    return null;
  }, [value]);

  const fetch = useMemo(
    () => debounce((request, callback) => {
      fetchSecurityAutocomplete(request)
        .then((response) => callback(response));
    }, 400),
    [],
  );

  useEffect(() => {
    let active = true;

    if (inputValue === '') {
      setOptions(getValue() ? [getValue()] : []);
      return undefined;
    }
    fetch(inputValue, (results) => {
      if (active) {
        let newOptions = [];

        if (getValue()) {
          newOptions = [getValue()];
        }

        if (results) {
          newOptions = [...newOptions, ...results];
        }

        setOptions(newOptions);
      }
    });

    return () => {
      active = false;
    };
  }, [getValue(), inputValue, fetch]);

  return (
    <Autocomplete
      sx={{ width: 300 }}
      isOptionEqualToValue={(option, selectedValue) => option.shortname === selectedValue.shortname
         && option.symbol === selectedValue.symbol}
      getOptionLabel={(option) => (typeof option === 'string' ? option : option.symbol)}
      filterOptions={(x) => x}
      options={options}
      autoComplete
      includeInputInList
      filterSelectedOptions
      disableClearable
      loading={loading()}
      value={getValue()}
      noOptionsText="No Securities"
      onChange={handleChange}
      onInputChange={(event, newInputValue) => setInputValue(newInputValue)}
      renderInput={(inputParams) => (
        <TextField {...inputParams} fullWidth />
      )}
      renderOption={(props, option) => {
        const { symbol, shortname, longname } = option;

        const symbolMatches = match(
          symbol,
          inputValue,
          { findAllOccurrences: true, insideWords: true },
        ) || [];
        const shortnameMatches = match(
          shortname || longname || symbol,
          inputValue,
          { findAllOccurrences: true, insideWords: true },
        ) || [];

        const symbolParts = parse(
          symbol,
          symbolMatches,
        );
        const shortnameParts = parse(
          shortname || longname || symbol,
          shortnameMatches,
        );
        return (
          <li {...props} key={shortname || longname || symbol + symbol}>
            <Grid container alignItems="center">
              <Grid item sx={{ wordWrap: 'break-word' }}>
                {symbolParts.map((part, index) => (
                  <Box
                    // eslint-disable-next-line react/no-array-index-key
                    key={index}
                    component="span"
                    sx={{ fontWeight: part.highlight ? 'bold' : 'regular' }}
                  >
                    {part.text}
                  </Box>
                ))}
                <Typography variant="body2" color="text.secondary">
                  {shortnameParts.map((part, index) => (
                    <Box
                    // eslint-disable-next-line react/no-array-index-key
                      key={index}
                      component="span"
                      sx={{ fontWeight: part.highlight ? 'bold' : 'regular', color: part.highlight ? '#5b5f66' : 'inherit' }}
                    >
                      {part.text}
                    </Box>
                  ))}
                </Typography>
              </Grid>
            </Grid>
          </li>
        );
      }}
    />
  );
};

SecuritiesAutoComplete.propTypes = {
  field: PropTypes.string.isRequired,
  id: PropTypes.number.isRequired,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
  ]).isRequired,
};

export default SecuritiesAutoComplete;
