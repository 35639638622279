import React from 'react';
import PropTypes from 'prop-types';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const FaqGroupItem = ({ title, items }) => {
  const theme = useTheme();
  return (
    <Box>
      <Box marginBottom={2}>
        <Typography fontWeight={700} variant="h5">
          {title}
        </Typography>
      </Box>
      <Box>
        {items.map((item, i) => (
          <Box
            component={Accordion}
            key={item.title}
            padding={1}
            marginBottom={i === item.length - 1 ? 0 : 2}
            borderRadius={`${theme.spacing(1)} !important`}
            sx={{
              '&::before': {
                display: 'none',
              },
            }}
          >
            <Box
              component={AccordionSummary}
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id={`panel1a-header--${i}`}
            >
              <Typography fontWeight={600}>{item.title}</Typography>
            </Box>
            <AccordionDetails>
              <Typography color="text.secondary">{item.subtitle}</Typography>
            </AccordionDetails>
          </Box>
        ))}
      </Box>
    </Box>
  );
};

FaqGroupItem.propTypes = {
  title: PropTypes.string.isRequired,
  items: PropTypes.arrayOf(PropTypes.shape({
    title: PropTypes.string.isRequired,
    subtitle: PropTypes.string.isRequired,
  })).isRequired,
};

const Content = () => (
  <Box>
    <Box marginBottom={6}>
      <FaqGroupItem
        title="Basics"
        items={[
          {
            title: 'Do i have to pay to use wealthlucid?',
            subtitle:
                'No. wealthlucid is completly free to use. There is no need to pay to be able to profit from this wonderful app.',
          },
          {
            title: 'Are my broker\'s credentials stored?',
            subtitle:
                'No the credentials are only used once to generate access and then access data is stored.',
          },
          {
            title: 'Is my money going through wealthlucid?',
            subtitle:
                'No we do not handle any money. Wealthlucid only send orders to the broker and the orders are processed by the brokers.',
          },
        ]}
      />
    </Box>
    {/* <Box marginBottom={6}>
      <FaqGroupItem
        title="Account & settings"
        items={[
          {
            title: 'Can I purchase a gift certificate?',
            subtitle:
                'Sed ut perspiciatis unde omnis iste natus error
                 sit voluptatem accusantium doloremque laudantium
                 , totam rem aperiam, eaque ipsa quae ab illo inventore
                  veritatis et quasi architecto beatae vitae dicta sunt explicabo.',
          },
          {
            title: 'What is your return policy?',
            subtitle:
                'Sed ut perspiciatis unde omnis iste natus error
                 sit voluptatem accusantium doloremque laudantium,
                  totam rem aperiam, eaque ipsa quae ab illo inventore
                   veritatis et quasi architecto beatae vitae dicta sunt explicabo.',
          },
          {
            title: 'Do you sell gift cards?',
            subtitle:
                'Sed ut perspiciatis unde omnis iste natus error
                 sit voluptatem accusantium doloremque laudantium,
                  totam rem aperiam, eaque ipsa quae ab illo
                   inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo.',
          },
          {
            title: 'Can I change plans later on?',
            subtitle:
                'Sed ut perspiciatis unde omnis iste natus error
                 sit voluptatem accusantium doloremque laudantium,
                  totam rem aperiam, eaque ipsa quae ab illo inventore
                   veritatis et quasi architecto beatae vitae dicta sunt explicabo.',
          },
          {
            title: 'Is this a subscription service?',
            subtitle:
                'Sed ut perspiciatis unde omnis iste natus error sit
                 voluptatem accusantium doloremque laudantium, totam
                 rem aperiam, eaque ipsa quae ab illo inventore veritat
                 is et quasi architecto beatae vitae dicta sunt explicabo.',
          },
        ]}
      />
    </Box>
    <Box>
      <FaqGroupItem
        title="Security"
        items={[
          {
            title: 'Can I purchase a gift certificate?',
            subtitle:
                'Sed ut perspiciatis unde omnis iste natus
                error sit voluptatem accusantium doloremque
                laudantium, totam rem aperiam, eaque ipsa quae
                 ab illo inventore veritatis et quasi architecto
                  beatae vitae dicta sunt explicabo.',
          },
          {
            title: 'What is your return policy?',
            subtitle:
                'Sed ut perspiciatis unde omnis iste natus error
                 sit voluptatem accusantium doloremque laudantium,
                  totam rem aperiam, eaque ipsa quae ab illo
                  inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo.',
          },
          {
            title: 'Do you sell gift cards?',
            subtitle:
                'Sed ut perspiciatis unde omnis iste natus error
                sit voluptatem accusantium doloremque laudantium,
                 totam rem aperiam, eaque ipsa quae ab illo inventore
                 veritatis et quasi architecto beatae vitae dicta sunt explicabo.',
          },
          {
            title: 'Can I change plans later on?',
            subtitle:
                'Sed ut perspiciatis unde omnis iste natus error
                sit voluptatem accusantium doloremque laudantium,
                 totam rem aperiam, eaque ipsa quae ab illo inventore
                  veritatis et quasi architecto beatae vitae dicta sunt explicabo.',
          },
          {
            title: 'Is this a subscription service?',
            subtitle:
                'Sed ut perspiciatis unde omnis iste natus error sit
                 voluptatem accusantium doloremque laudantium, totam
                  rem aperiam, eaque ipsa quae ab illo inventore veritatis
                   et quasi architecto beatae vitae dicta sunt explicabo.',
          },
        ]}
      />
    </Box> */}
  </Box>
);

export default Content;
