import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
// import { useMediaQuery, useTheme } from '@mui/material';
import CreditCards from './CreditCards';
import Container from '../../Container';

/* const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
   defaultMatches: true,
 }); */
const Security = () => (
  <Container>
    <Grid container spacing={4}>
      <Grid item xs={12} md={6}>
        <Box textAlign="left">
          <Box marginBottom={2}>
            <Typography
              variant="h4"
              sx={{
                fontWeight: 700,
              }}
            >
              We protect your data
            </Typography>
          </Box>
          <Box marginBottom={3}>
            <Typography variant="h6" component="p" color="text.secondary">
              It is our priority to ensure your security.
              We don&apos;t want to create any concerns, we want to remove them.
              To ensure the safety of our customers, we utilize the latest security technologies.
            </Typography>
          </Box>
        </Box>
      </Grid>
      <Grid item xs={12} md={6}>
        <Box height={1} width={1} display="flex" justifyContent="center">
          <Box height={1} width={1} maxWidth={450}>
            <CreditCards width="100%" height="100%" />
          </Box>
        </Box>
      </Grid>
    </Grid>
  </Container>
);
export default Security;
