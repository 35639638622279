import React from 'react';
import PropTypes from 'prop-types';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { useTheme } from '@mui/material/styles';
// import useMediaQuery from '@mui/material/useMediaQuery';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';

const Spotlight = ({
  title, subtitle, image, alignLeft,
}) => {
  const theme = useTheme();

  // const isMd = useMediaQuery(theme.breakpoints.up('md'), {
  //   defaultMatches: true,
  // });

  return (
    <Grid
      container
      spacing={4}
      direction={alignLeft ? 'row' : 'row-reverse'}
    >
      <Grid item container xs={12} md={6} alignItems="center">
        <Box>
          <Box marginBottom={2}>
            <Typography
              variant="h4"
              color="text.primary"
              sx={{ fontWeight: 700 }}
            >
              {title}
            </Typography>
          </Box>
          <Box marginBottom={4}>
            <Typography variant="h6" component="p" color="text.secondary">
              {subtitle}
            </Typography>
          </Box>
        </Box>
      </Grid>
      <Grid
        item
        container
        alignItems="center"
        justifyContent="center"
        xs={12}
        md={6}
        sx={{ mb: 2 }}
      >
        <Box
          component={LazyLoadImage}
          height={1}
          width={1}
          src={image}
          alt="feature image overview"
          effect="blur"
          boxShadow={3}
          borderRadius={2}
          maxWidth={600}
          sx={{
            filter: theme.palette.mode === 'dark' ? 'brightness(0.7)' : 'none',
          }}
        />
      </Grid>
    </Grid>
  );
};

Spotlight.propTypes = {
  image: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  alignLeft: PropTypes.bool.isRequired,
};

export default Spotlight;
