import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useFirestoreConnect } from 'react-redux-firebase';
import { useSelector, connect } from 'react-redux';
import styled from 'styled-components';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Card, Checkbox, FormControlLabel, FormGroup, Skeleton, Typography, capitalize, useTheme,
} from '@mui/material';
import WarningIcon from '@mui/icons-material/Warning';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { BOTTOM_NAVIGATION_HEIGHT, useMobileDevice } from '../../Common/responsiveUIHelper';
import { STRATEGIES, TRIGGERS } from '../constants';
import { isFsLoaded } from '../Utils/Utils';
import StockOrdersList from '../StockOrders/StockOrdersList';

const TransactionActivityWrapper = styled.div`
  height: ${({ isMobileDevice }) => (isMobileDevice ? `calc(100% - ${BOTTOM_NAVIGATION_HEIGHT})` : '100%')};
  width: 100%;
  overflow-y: auto;
  display: flex;
  flex-direction:  ${({ isMobileDevice }) => (isMobileDevice ? 'column' : 'row')};
  align-items: flex-start;
  justify-content: ${({ isMobileDevice }) => (isMobileDevice ? null : 'center')};
  background-color: ${(props) => props.theme.palette.background.default};
`;

const TransactionActivityCard = styled(Card)`
  margin-top: 16px;
  margin-right: 16px;
  padding: 16px;
  flex-shrink: 0;
`;

const TransactionActivityCardDesc = styled.div`
  text-align: left;
  margin-left: 15px;
  margin-top: 15px;
  `;

const TransactionActivityCardList = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 16px;
   ${({ transactionsNumber, isMobileDevice }) => (transactionsNumber === 0 && !isMobileDevice
    ? 'width: 624px' : null)};
`;

const convertedDate = (d) => {
  const cD = new Date(d.getTime());
  cD.setTime(cD.getTime() - cD.getTimezoneOffset() * 60 * 1000);
  return cD;
};

const toDateTime = function toDateTime(secs) {
  const options = {
    year: 'numeric', month: 'long', day: 'numeric', hour: '2-digit', minute: '2-digit',
  };
  const t = new Date(1970, 0, 1); // Epoch
  t.setSeconds(secs);
  return convertedDate(t).toLocaleString('en-US', options);
};

const TransactionsActivity = ({ auth }) => {
  useFirestoreConnect([
    {
      collection: 'transactions',
      where: [['uid', '==', auth.uid]],
      orderBy: ['created', 'desc'],
    },
  ]);
  const theme = useTheme();
  const isMobileDevice = useMobileDevice();
  const transactionsFirestore = useSelector((state) => state.firestore);
  const { transactions } = transactionsFirestore.ordered;
  const [filterAccounts, setFilterAccounts] = useState([]);
  const [isNotChecked, setIsNotChecked] = useState({});
  const [filteredTransactions, setfilteredTransactions] = useState([]);
  const [isAllChecked, setIsAllChecked] = useState(true);
  useEffect(() => {
    const uniqueAccounts = Object.values(
      transactions?.reduce((acc, item) => ({
        ...acc,
        [`${capitalize(item?.strategy?.broker || '')} - ${item?.strategy?.account}`]: {
          broker: item?.strategy?.broker,
          accountName: item?.strategy?.account,
          id: `${capitalize(item?.strategy?.broker || '')} - ${item?.strategy?.account}`,
        },
      }), {}) || {},
    );
    setFilterAccounts(uniqueAccounts);
  }, [transactions]);

  useEffect(() => {
    const isAll = filterAccounts.every((item) => !isNotChecked[`${capitalize(item?.broker || '')} - ${item?.accountName}`]);
    setIsAllChecked(isAll);
  }, [isNotChecked]);

  useEffect(() => {
    const checkedTransactions = Object.entries(transactions || [])
      .filter(([, transaction]) => !isNotChecked[`${capitalize(transaction?.strategy?.broker || '')} - ${transaction?.strategy?.account}`]);
    setfilteredTransactions(checkedTransactions);
  }, [transactions, isNotChecked]);

  const onAllFilterChange = (e) => {
    const newState = !e.target.checked;
    const filterChecks = {};
    filterAccounts?.forEach((item) => {
      filterChecks[`${capitalize(item?.broker || '')} - ${item?.accountName}`] = newState;
    });
    setIsNotChecked(filterChecks);
  };

  let transactionsActivityList = (
    <>
      <Skeleton
        sx={{ maxWidth: '800px', marginBottom: '24px' }}
        variant="rectangular"
        width="100%"
        height={300}
      />
      <Skeleton
        sx={{ maxWidth: '800px', marginBottom: '24px' }}
        variant="rectangular"
        width="100%"
        height={300}
      />
    </>
  );

  if (transactions?.length === 0 && isFsLoaded(transactionsFirestore)) {
    transactionsActivityList = <Typography variant="body1" gutterBottom component="div" style={{ margin: 16 }}>Once you start investing, the transactions activity is going to appear here</Typography>;
  }
  if (transactions?.length > 0) {
    transactionsActivityList = filteredTransactions.map(([id, {
      strategy, receipt, created, type, errorMessage,
    }]) => {
      const {
        recurrenceFrequency, amountTrigger, broker, account,
        amountFrozen, strategyType, triggerType,
        nickname, autoStrategy,
      } = strategy;
      const getStrategyCardDescription = () => {
        let strategyTypeMsg = '';
        let triggerTypeMsg = '';
        const whereMsg = `in ${broker} ${account} account`;

        if (strategyType === STRATEGIES.BUY_STOCKS) {
          strategyTypeMsg = 'securities bought';
        } else if (strategyType === STRATEGIES.REBALANCE) {
          strategyTypeMsg = 'account rebalancing';
          if (Number(amountFrozen)) {
            strategyTypeMsg += ` but ${amountFrozen}$`;
          }
        }

        if (triggerType === TRIGGERS.RECURRENCE) {
          triggerTypeMsg = `${recurrenceFrequency}`;
        } else if (triggerType === TRIGGERS.DEPOSIT) {
          triggerTypeMsg = `when depositing ${amountTrigger}$`;
        }

        return `${type === 'automatic' ? 'Automatic' : 'Manual'} ${strategyTypeMsg} ${triggerTypeMsg} ${whereMsg}`;
      };

      return (
        <Card
          key={id}
          style={{
            width: '100%', marginBottom: '24px', maxWidth: '800px', overflow: 'initial',
          }}
        >
          <TransactionActivityCardDesc>
            <Typography variant="h5" component="div">
              {nickname}
            </Typography>
            <Typography variant="body1" component="div">
              {created && created.seconds ? toDateTime(created.seconds) : 'NaN'}
            </Typography>
            <Typography variant="body1" gutterBottom={!errorMessage} component="div">
              {getStrategyCardDescription()}
            </Typography>
            {errorMessage ? (
              <Typography variant="body1" gutterBottom color="error" component="div">
                <WarningIcon style={{ verticalAlign: 'middle' }} color="error" />
                {`Error: ${errorMessage}`}
              </Typography>
            ) : null }
          </TransactionActivityCardDesc>
          <StockOrdersList autoStrategy={autoStrategy} receipt={receipt} />
        </Card>
      );
    });
  }

  const getFilters = () => (
    <>
      <Typography variant="h4"> Accounts</Typography>
      <FormGroup>
        <FormControlLabel control={<Checkbox checked={isAllChecked} onChange={onAllFilterChange} />} label="All accounts" />
        {filterAccounts?.map(({ broker, accountName, id }) => (
          <FormControlLabel
            key={id}
            label={`${capitalize(broker || '')} - ${accountName}`}
            control={(
              <Checkbox
                checked={!isNotChecked?.[id]}
                onChange={(e) => setIsNotChecked({
                  ...isNotChecked,
                  [id]: !e.target.checked,
                })}
              />
        )}
          />
        ))}
      </FormGroup>
    </>
  );

  const getFiltersComponent = () => {
    let filtersComponent = null;
    if (transactions?.length === 0) {
      filtersComponent = null;
    } else if (isMobileDevice) {
      filtersComponent = (
        <Accordion sx={{ width: '100%' }}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="filters"
            id="filters"
          >
            <Typography>Filter Accounts</Typography>
          </AccordionSummary>
          <AccordionDetails>
            {getFilters()}
          </AccordionDetails>
        </Accordion>
      );
    } else {
      filtersComponent = (
        <TransactionActivityCard>
          {getFilters()}
        </TransactionActivityCard>
      );
    }
    return filtersComponent;
  };

  return (
    <TransactionActivityWrapper theme={theme} isMobileDevice={isMobileDevice}>
      {getFiltersComponent()}
      <TransactionActivityCardList
        transactionsNumber={filteredTransactions?.length}
        isMobileDevice={isMobileDevice}
      >
        {transactionsActivityList}
      </TransactionActivityCardList>
    </TransactionActivityWrapper>
  );
};

TransactionsActivity.propTypes = {
  auth: PropTypes.shape({
    uid: PropTypes.string.isRequired,
  }).isRequired,
};

const enhance = connect(
  // Map redux state to component props
  ({ firebase: { auth } }) => ({
    auth,
  }),
);

export default enhance(TransactionsActivity);
