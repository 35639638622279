import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import { onAuthStateChanged } from 'firebase/auth';
import * as firebaseui from 'firebaseui';
import 'firebaseui/dist/firebaseui.css';
import styled from 'styled-components';
import { useTheme } from '@mui/material';

const Dark = styled.div`
.mdl-card {
  background: ${'#2D364E'} !important;
}

.mdl-textfield__label {
  color: ${({ theme }) => theme.palette.text.primary} !important;
}

.firebaseui-title {
  color: ${({ theme }) => theme.palette.text.primary}
}

.mdl-textfield__input{
  color: ${({ theme }) => theme.palette.text.primary} !important;
  border-color: ${({ theme }) => theme.palette.text.primary};
  background: ${'#2D364E'} !important;
  -webkit-box-shadow: 0 0 0px 1000px  ${'#2D364E'} inset;
  -webkit-text-fill-color: ${({ theme }) => theme.palette.text.primary} !important;
}
`;

const StyledFirebaseAuth = ({
  uiConfig, firebaseAuth, className, uiCallback,
}) => {
  const [userSignedIn, setUserSignedIn] = useState(false);
  const elementRef = useRef(null);
  const theme = useTheme();

  const themeMode = window.localStorage.getItem('themeMode');

  useEffect(() => {
    // Get or Create a firebaseUI instance.
    const firebaseUiWidget = firebaseui.auth.AuthUI.getInstance()
     || new firebaseui.auth.AuthUI(firebaseAuth);
    if (uiConfig.signInFlow === 'popup') { firebaseUiWidget.reset(); }

    // We track the auth state to reset firebaseUi if the user signs out.
    const unregisterAuthObserver = onAuthStateChanged(firebaseAuth, (user) => {
      if (!user && userSignedIn) { firebaseUiWidget.reset(); }
      setUserSignedIn(!!user);
    });

    // Trigger the callback if any was set.
    if (uiCallback) { uiCallback(firebaseUiWidget); }

    // Render the firebaseUi Widget.
    // @ts-ignore
    firebaseUiWidget.start(elementRef.current, uiConfig);

    return () => {
      unregisterAuthObserver();
      firebaseUiWidget.reset();
    };
  }, [firebaseui, uiConfig]);

  return themeMode && themeMode === 'dark'
    ? <Dark className={className} ref={elementRef} theme={theme} />
    : <div className={className} ref={elementRef} />;
};

StyledFirebaseAuth.propTypes = {
  className: PropTypes.string.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  firebaseAuth: PropTypes.object.isRequired,
  uiCallback: PropTypes.func.isRequired,
  uiConfig: PropTypes.shape({
    signInFlow: PropTypes.string,
  }).isRequired,
};

export default StyledFirebaseAuth;
