import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import SettingsIcon from '@mui/icons-material/Settings';
import TimelineIcon from '@mui/icons-material/Timeline';
import CloseIcon from '@mui/icons-material/Close';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import MenuItem from '@mui/material/MenuItem';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { isEmpty, isLoaded } from 'react-redux-firebase';
import { connect } from 'react-redux';
import {
  IconButton, Tab, Tabs, Typography,
} from '@mui/material';
import logo from './wlLogo.png';
import shortLogo from './shortWlLogo.png';
import ThemeModeToggler from '../../../Theme/ThemeModeToggler';
import { useMobileDevice } from '../responsiveUIHelper';

const homePages = ['how it works', 'about'];

const TopBar = styled(Paper)`
  text-align: left;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  height: 57px;
  z-index: 3;
`;

TopBar.Login = styled.div`
  align-self: center;
`;

TopBar.Logo = styled.a`
  margin-left: 15px;
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-self: flex-start;
`;

TopBar.LogoImg = styled.img`
  margin-right: 12px;
  height: 28px;
`;

const NavigationBar = ({
  signOut, auth,
}) => {
  const location = useLocation().pathname;
  const navigate = useNavigate();
  const isMobileDevice = useMobileDevice();

  const [anchorElNav, setAnchorElNav] = React.useState(null);

  const isLogged = isLoaded(auth) && !isEmpty(auth);
  const isActionState = location === '/strategies/new' || location === '/login' || (location.split('/strategies/')[1] && location.split('/strategies/')[1].length);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const linkClick = (target) => {
    const linkClickFunction = async () => {
      setAnchorElNav(null);
      if (target === 'logout') {
        await signOut();
        navigate('/login');
      } else if (target === 'how it works') {
        navigate('/how-it-works');
      } else {
        navigate(`/${target}`);
      }
    };
    return linkClickFunction;
  };

  const getNavigationValue = () => {
    let navigationValue = false;
    if (location === '/strategies' || location === '/') {
      navigationValue = 0;
    } else if (location === '/activity') {
      navigationValue = 1;
    } else if (location.includes('/settings')) {
      navigationValue = 2;
    }
    return navigationValue;
  };

  const getNavigationTitle = () => {
    let navigationTitle = '';
    if (location !== '/login') {
      navigationTitle = `${location.includes('/new') ? 'Add' : 'Edit'} investing strategy`;
    }
    return navigationTitle;
  };
  return (
    <AppBar position="static" color="background">
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <Box id="logo" sx={{ flexGrow: 1, display: 'flex', cursor: 'pointer' }}>
            <TopBar.LogoImg src={isActionState && isMobileDevice ? shortLogo : logo} alt="logo" onClick={() => navigate('/')} />
          </Box>
          <Typography noWrap variant="h5" sx={{ flexGrow: 1, display: { xs: isActionState ? 'flex' : 'none', md: 'none' }, mr: 2 }}>
            {getNavigationTitle()}
          </Typography>
          <Box id="home pages" sx={{ flexGrow: 1, display: { xs: 'none', md: isLogged || isActionState ? 'none' : 'flex' } }}>
            {homePages.map((page) => (
              <Button
                variant={page === 'Sign in' ? 'contained' : 'text'}
                key={page}
                onClick={linkClick(page)}
                size="large"
                sx={{ my: 2, display: 'block' }}
              >
                {page}
              </Button>
            ))}
          </Box>
          <Tabs
            sx={{ flexGrow: 1, display: { xs: 'none', md: isLogged && !isActionState ? 'flex' : 'none' } }}
            value={getNavigationValue()}
            aria-label="navigation tabs"
          >
            <Tab component={Link} to="/strategies" icon={<AccountBalanceIcon />} iconPosition="start" label="Strategies" />
            <Tab component={Link} to="/activity" icon={<TimelineIcon />} iconPosition="start" label="Activity" />
            <Tab component={Link} to="/settings" icon={<SettingsIcon />} iconPosition="start" label="Brokers" />
          </Tabs>
          <ThemeModeToggler sx={{ mr: 1, display: { xs: 'none', md: !isLogged || isActionState ? 'none' : 'flex' } }} />
          <Box id="Sign in/out" sx={{ flexGrow: 0, display: isActionState ? 'none' : 'flex' }}>
            <Button
              variant={isLogged ? 'text' : 'contained'}
              key={isLogged ? 'logout' : 'signin'}
              onClick={linkClick(isLogged ? 'logout' : 'login')}
              sx={{ my: 2, display: 'block' }}
            >
              {isLogged ? 'Log out' : 'Sign in'}
            </Button>
          </Box>
          <IconButton
            sx={{ flexGrow: 0, display: { xs: isLogged && isActionState ? 'flex' : 'none', md: 'none' } }}
            component={Link}
            to="/strategies"
            size="large"
          >
            <CloseIcon fontSize="inherit" />
          </IconButton>
          <Box id="mobile home menu" sx={{ flexGrow: 0, display: { xs: isLogged ? 'none' : 'flex', md: 'none' } }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: 'block', md: 'none' },
              }}
            >
              {homePages.map((page) => (
                <MenuItem key={page} onClick={linkClick(page)}>
                  <Typography textAlign="center">{page}</Typography>
                </MenuItem>
              ))}
              <MenuItem key="Sign in" onClick={linkClick(isLogged ? 'Log out' : 'login')}>
                <Typography textAlign="center">Sign in</Typography>
              </MenuItem>
            </Menu>
          </Box>
        </Toolbar>
      </Container>
      <Paper
        sx={{
          position: 'fixed', bottom: 0, left: 0, right: 0, zIndex: 3, display: { xs: isLogged && !isActionState ? 'block' : 'none', md: 'none' },
        }}
        elevation={3}
      >
        <BottomNavigation
          showLabels
          value={getNavigationValue()}
        >
          <BottomNavigationAction component={Link} to="/strategies" label="Strategies" icon={<AccountBalanceIcon />} />
          <BottomNavigationAction component={Link} to="/activity" label="Activity" icon={<TimelineIcon />} />
          <BottomNavigationAction component={Link} to="/settings" label="Brokers" icon={<SettingsIcon />} />
        </BottomNavigation>
      </Paper>
    </AppBar>
  );
};

NavigationBar.propTypes = {
  signOut: PropTypes.func.isRequired,
  auth: PropTypes.shape({
    uid: PropTypes.string,
    displayName: PropTypes.string,
    email: PropTypes.string,
  }),
};

NavigationBar.defaultProps = {
  auth: {},
};

const enhance = connect(
  // Map redux state to component props
  ({ firebase: { auth } }) => ({
    auth,
  }),
);

export default enhance(NavigationBar);
