import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import { Link } from 'react-router-dom';

const CallToAction = () => {
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });

  return (
    <Box textAlign="left">
      <Typography
        variant="h3"
        color="text.primary"
        gutterBottom
        sx={{
          fontWeight: 700,
        }}
      >
        Make your life
        {' '}
        <Typography
          color="primary"
          component="span"
          variant="h3"
          sx={{
            fontWeight: 700,
          }}
        >
          easier
          {' '}
        </Typography>
        and your investments
        {' '}
        <Typography
          color="primary"
          component="span"
          variant="h3"
          sx={{
            fontWeight: 700,
          }}
        >
          smarter
        </Typography>
      </Typography>
      <Typography
        variant="h6"
        component="p"
        color="text.secondary"
        sx={{ fontWeight: 400, maxWidth: 700 }}
      >
        Set your investments on autopilot in just a few minutes and
        focus your efforts where they matter most. Begin your journey with one click.
      </Typography>
      <Box
        display="flex"
        flexDirection={{ xs: 'column', sm: 'row' }}
        alignItems={{ xs: 'stretched', sm: 'flex-start' }}
        marginTop={4}
      >
        <Button
          component={Link}
          variant="contained"
          color="primary"
          size="large"
          fullWidth={!isMd}
          to="/login"
        >
          Get Started
        </Button>
      </Box>
    </Box>
  );
};

export default CallToAction;
