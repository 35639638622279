export default {
  apiKey: 'AIzaSyAKogeYrPJDGG5IV3lgioD3xqSTmQkgyMM',
  authDomain: 'wealthlucid.com',
  databaseURL: 'https://stockswing-59231-default-rtdb.firebaseio.com',
  projectId: 'stockswing-59231',
  storageBucket: 'stockswing-59231.appspot.com',
  messagingSenderId: '830804692708',
  appId: '1:830804692708:web:705d7b018440923e6426f1',
  measurementId: 'G-S4K40GSD9Y',
};
