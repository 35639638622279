// import { useMediaQuery } from '@mui/material';
// import { useTheme } from 'styled-components';

import { useMediaQuery, useTheme } from '@mui/material';

// export const BP_PALM = 0;
// export const BP_LAP = 1;
// export const BP_PORTABLE = 1;
// export const BP_DESK = 2;
// export const BP_WALL = 3;

// export function getBreakpoint() {
//   let breakPoint = BP_PALM;
//   if (window.innerWidth >= 600) {
//     breakPoint = BP_LAP;
//   }
//   if (window.innerWidth >= 960) {
//     breakPoint = BP_DESK;
//   }
//   if (window.innerWidth >= 1600) {
//     breakPoint = BP_WALL;
//   }
//   return breakPoint;
// }

// function getWidth() {
//   return Math.max(
//     document.body.scrollWidth,
//     document.documentElement.scrollWidth,
//     document.body.offsetWidth,
//     document.documentElement.offsetWidth,
//     document.documentElement.clientWidth,
//   );
// }

// export function isMobileDevice() {
//   return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|
// Opera Mini/i.test(navigator.userAgent) || getWidth() < 900;
// }
// export const isMobileDevice = () => {
//   const theme = useTheme();
//   return !useMediaQuery(theme.breakpoints.up('sm'));
// };

export const useMobileDevice = () => {
  const theme = useTheme();
  return !useMediaQuery(theme.breakpoints.up('md'));
};

export const BOTTOM_NAVIGATION_HEIGHT = '60px';
