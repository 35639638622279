import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import CloseIcon from '@mui/icons-material/Close';
import styled from 'styled-components';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import SaveIcon from '@mui/icons-material/Save';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import {
  GridRowModes,
  DataGrid,
  GridToolbarContainer,
  GridActionsCellItem,
} from '@mui/x-data-grid';
import { Box } from '@mui/system';
import {
  Typography, useTheme,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import {
  ORDER_TYPES, STRATEGIES,
} from '../constants';
import {
  currencyFormatter, fixNb, getSecuritySymbol, useCurrentSecurities,
} from '../Utils/Utils';
import SecuritiesAutoComplete from './SecuritiesAutoComplete';
import { useMobileDevice } from '../../Common/responsiveUIHelper';

// eslint-disable-next-line react/prop-types
const EditToolbar = (strategyType) => ({ setAutoStrategy, setRowModesModel }) => {
  const handleClick = () => {
    const id = Math.random();
    setAutoStrategy((oldRows) => [...oldRows, {
      id, stock: '', market: '', amount: '', orderType: '', isNew: true,
    }]);
    setRowModesModel((oldModel) => ({
      ...oldModel,
      [id]: { mode: GridRowModes.Edit, fieldToFocus: 'stock' },
    }));
  };

  return (
    <GridToolbarContainer>
      <Button variant="outlined" color="primary" startIcon={<AddIcon />} onClick={handleClick}>
        {strategyType === STRATEGIES.REBALANCE ? 'New Allocation' : 'Add Security'}
      </Button>
    </GridToolbarContainer>
  );
};

EditToolbar.propTypes = {
  setAutoStrategy: PropTypes.func.isRequired,
  setRowModesModel: PropTypes.func.isRequired,
};

const InvestStrategySecuritiesWrapper = styled.div`
  margin: ${({ isMobileDevice }) => (isMobileDevice ? '0' : '0px 8px 8px 8px')};
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  flex-wrap: wrap;
  width: 100%;
`;

const InvestStrategySecurities = ({
  setAutoStrategy, strategyType, autoStrategy, error, accountBalance,
}) => {
  const {
    currentSecuritiesTotal, currentSecuritiesPrices,
  } = useCurrentSecurities(autoStrategy, accountBalance);
  const isMobileDevice = useMobileDevice();
  const [rowModesModel, setRowModesModel] = useState({});

  const handleRowEditStart = (params, event) => {
    // eslint-disable-next-line no-param-reassign
    event.defaultMuiPrevented = true;
  };

  const handleRowEditStop = (params, event) => {
    // eslint-disable-next-line no-param-reassign
    event.defaultMuiPrevented = true;
  };

  const handleEditClick = (id) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
  };

  const handleSaveClick = (id) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
  };

  const handleDeleteClick = (id) => () => {
    setAutoStrategy(autoStrategy.filter((row) => row.id !== id));
  };

  const handleCancelClick = (id) => () => {
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true },
    });

    const editedRow = autoStrategy.find((row) => row.id === id);
    if (editedRow.isNew) {
      setAutoStrategy(autoStrategy.filter((row) => row.id !== id));
    }
  };

  const processRowUpdate = (newRow) => {
    const updatedRow = { ...newRow, isNew: false };
    setAutoStrategy(autoStrategy.map((row) => (row.id === newRow.id ? updatedRow : row)));
    return updatedRow;
  };

  const BuyStockOrderOptions = [
    { value: ORDER_TYPES.AMOUNT_MARKET_BUY, label: 'Amount ($)' },
    { value: ORDER_TYPES.NB_MARKET_BUY, label: 'Share number' },
  ].filter(Boolean);

  const RebalanceOrderOptions = [
    { value: ORDER_TYPES.AMOUNT_MARKET_BUY, label: 'Fixed amount ($)' },
    { value: ORDER_TYPES.PERCENT_MARKET_BUY, label: 'Security percentage (%)' },
  ];

  const renderCellFunction = (params) => <Typography variant="body2" style={{ whiteSpace: 'normal' }}>{params.formattedValue}</Typography>;

  const columns = [
    {
      field: 'stock',
      headerName: 'Security',
      minWidth: 260,
      flex: 1,
      editable: true,
      valueFormatter: ({ value }) => (typeof value === 'string' ? value : `${value.symbol} ${value.shortname}`),
      renderEditCell: (params) => <SecuritiesAutoComplete {...params} />,
      renderCell: renderCellFunction,
    },
    {
      field: 'orderType',
      headerName: 'Order Type',
      type: 'singleSelect',
      minWidth: isMobileDevice ? 118 : 230,
      flex: 1,
      editable: true,
      valueGetter: ({ value }) => (strategyType === STRATEGIES.REBALANCE
        ? ORDER_TYPES.PERCENT_MARKET_BUY : value),
      renderCell: renderCellFunction,
      valueOptions: () => (strategyType === STRATEGIES.REBALANCE
        ? RebalanceOrderOptions : BuyStockOrderOptions),
      valueFormatter: ({ value }) => {
        const orderOptions = strategyType === STRATEGIES.REBALANCE
          ? RebalanceOrderOptions : BuyStockOrderOptions;
        const def = orderOptions.find((opt) => opt.value === value);
        if (def) {
          return def.label;
        }
        return value;
      },
    },
    {
      field: 'amount',
      headerName: strategyType === STRATEGIES.REBALANCE ? 'Target (%)' : 'Amount',
      type: strategyType === STRATEGIES.REBALANCE ? 'number' : 'string',
      minWidth: 68,
      flex: 1,
      editable: true,
      renderCell: renderCellFunction,
      valueFormatter: ({ value, id, api }) => {
        const { orderType: rowOrderType } = api.getRowParams(id).row;
        if (rowOrderType === ORDER_TYPES.AMOUNT_MARKET_BUY
           || rowOrderType === ORDER_TYPES.FRACTIONAL_BUY) {
          return currencyFormatter.format(value);
        } if (rowOrderType === ORDER_TYPES.PERCENT_MARKET_BUY) {
          return `${value}%`;
        }
        return value;
      },
    },
    {
      field: 'current',
      headerName: 'Current (%)',
      type: 'string',
      minWidth: 38,
      flex: 1,
      editable: false,
      valueGetter: ({ row: { stock } }) => (
        // || rowModesModel[id]?.mode === GridRowModes.Edit
        Object.keys(currentSecuritiesPrices).length === 0 ? ''
          : `${currentSecuritiesTotal === 0 ? 0
            : fixNb((currentSecuritiesPrices[getSecuritySymbol(stock)] / currentSecuritiesTotal) * 100)}%`)
      ,
    },
    {
      field: 'actions',
      type: 'actions',
      minWidth: 65,
      flex: 1,
      getActions: ({ id, row: { stock } }) => {
        const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;

        if (isInEditMode) {
          return [
            <GridActionsCellItem
              icon={<SaveIcon />}
              label="Save"
              onClick={handleSaveClick(id)}
              color="primary"
            />,
            <GridActionsCellItem
              icon={<CloseIcon />}
              label="Cancel"
              onClick={handleCancelClick(id)}
              color="primary"
            />,
          ];
        }

        return [
          <GridActionsCellItem
            icon={<EditIcon />}
            label="Edit"
            className="textPrimary"
            onClick={handleEditClick(id)}
            color="inherit"
          />,
          <GridActionsCellItem
            icon={currentSecuritiesPrices[getSecuritySymbol(stock)]
              ? <RemoveCircleIcon /> : <DeleteIcon />}
            label="Delete"
            onClick={handleDeleteClick(id)}
            color="inherit"
          />,
        ];
      },
    },
  ].filter(Boolean);
  const theme = useTheme();
  // const useStyles = makeStyles(() => ({ width: '100%', height: '100%' }));

  const useStyles = makeStyles(() => ({
    overlayMod: { width: '100% !important', height: '100% !important' },
  }));

  const customWrapper = useStyles();

  return (
    <InvestStrategySecuritiesWrapper isMobileDevice={isMobileDevice}>
      <Box
        sx={{
          height: 315,
          width: isMobileDevice ? '100%' : 710,
          '& .actions': {
            color: 'text.secondary',
          },
          '& .textPrimary': {
            color: 'text.primary',
          },
        }}
      >
        <DataGrid
          rows={autoStrategy}
          columns={columns}
          editMode="row"
          rowModesModel={rowModesModel}
          onRowEditStart={handleRowEditStart}
          onRowEditStop={handleRowEditStop}
          processRowUpdate={processRowUpdate}
          columnVisibilityModel={{
            orderType: strategyType !== STRATEGIES.REBALANCE,
            current: strategyType === STRATEGIES.REBALANCE,
          }}
          disableSelectionOnClick
          disableColumnSelector
          disableDensitySelector
          disableColumnFilter
          hideFooter
          autoheight
          classes={{ overlayWrapperInner: customWrapper.overlayMod }}
          style={error ? { border: `1px solid ${theme.palette.error.main}` } : null}
          components={{
            Toolbar: EditToolbar(strategyType),
            noRowsOverlay: () => { <div />; },
          }}
          componentsProps={{
            toolbar: { setAutoStrategy, setRowModesModel },
          }}
          experimentalFeatures={{ newEditingApi: true }}
        />
      </Box>
    </InvestStrategySecuritiesWrapper>
  );
};

InvestStrategySecurities.defaultProps = {
  strategyType: '',
  triggerType: '',
  error: false,
};

InvestStrategySecurities.propTypes = {
  autoStrategy: PropTypes.arrayOf(
    PropTypes.shape({
      Stock: PropTypes.string,
      Market: PropTypes.string,
      OrderType: PropTypes.number,
      Amount: PropTypes.number,
    }),
  ).isRequired,
  setAutoStrategy: PropTypes.func.isRequired,
  strategyType: PropTypes.string,
  triggerType: PropTypes.string,
  error: PropTypes.bool,
  broker: PropTypes.string.isRequired,
  accountBalance: PropTypes.shape({
    balance: PropTypes.number,
    positions: PropTypes.arrayOf(PropTypes.shape({
      price: PropTypes.number,
      symbol: PropTypes.string,
    })),
  }).isRequired,
};

export default InvestStrategySecurities;
