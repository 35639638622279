import React from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import styled from 'styled-components';

const SplashScreenWrapper = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const SplashScreen = () => (
  <SplashScreenWrapper>
    <CircularProgress />
  </SplashScreenWrapper>
);

export default SplashScreen;
