export const STRATEGIES = {
  REBALANCE: 'rebalance',
  BUY_STOCKS: 'buyStocks',
};

export const TRIGGERS = {
  RECURRENCE: 'recurrence',
  DEPOSIT: 'deposit',
};

export const ORDER_TYPES = {
  FRACTIONAL_BUY: 0,
  AMOUNT_MARKET_BUY: 1,
  NB_MARKET_BUY: 2,
  PERCENT_MARKET_BUY: 3,
  AMOUNT_MARKET_SELL: 4,
};

export const ORDER_TYPES_CAPTIONS = {
  [ORDER_TYPES.FRACTIONAL_BUY]: 'Amount ($)',
  [ORDER_TYPES.AMOUNT_MARKET_BUY]: 'Amount ($)',
  [ORDER_TYPES.NB_MARKET_BUY]: 'Share number',
  [ORDER_TYPES.PERCENT_MARKET_BUY]: 'Percentage',
  [ORDER_TYPES.AMOUNT_MARKET_SELL]: 'Amount ($)',
};

export const RECURRENCES_TIMES = {
  daily: 1,
  weekly: 7,
  biweekly: 14,
  monthly: 30,
  annually: 365,
};

export const WEALTHSIMPLE = 'wealthsimple';
export const QUESTRADE = 'questrade';

// Prod
export const QUESTRADE_LOGIN_URL = 'https://login.questrade.com/oauth2/authorize?client_id=mTtz7NDYSNK//x2SpWzLmqSrgIh/JA&response_type=token&redirect_uri=';

// Practice account
// export const QUESTRADE_LOGIN_URL = 'https://practicelogin.questrade.com/oauth2/authorize?client_id=TkhPVrj8qvVauZ8Huco2aAAvCrhzXQ&response_type=token&redirect_uri=https://tolocalhost.com/settings/questrade/';

export const SUPPORT_EMAIL = 'support@wealthlucid.com';
