import React, { forwardRef, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { NumericFormat } from 'react-number-format';

const InputMoneyFormat = forwardRef((props, ref) => {
  const { onChange, ...other } = props;

  return (
    <NumericFormat
      {...other}
      getInputRef={ref}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.floatValue,
          },
        });
      }}
      thousandSeparator
      suffix="$"
    />
  );
});

InputMoneyFormat.defaultProps = {
  name: '',
};

InputMoneyFormat.propTypes = {
  name: PropTypes.string,
  onChange: PropTypes.func.isRequired,
};

const MoneyFormat = ({ value, customComponent: CustomComponent, ...props }) => (
  <NumericFormat
    value={value}
    displayType="text"
    thousandSeparator
    suffix="$"
    renderText={(text) => (CustomComponent
      ? <CustomComponent {...props}>{text}</CustomComponent> : <span>{text}</span>)}
    {...props}
  />
);

MoneyFormat.defaultProps = { customComponent: null };

MoneyFormat.propTypes = {
  customComponent: PropTypes.elementType,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]).isRequired,
};

const InputPercentFormat = forwardRef((props, ref) => {
  const { onChange, ...other } = props;

  return (
    <NumericFormat
      {...other}
      getInputRef={ref}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      suffix="%"
    />
  );
});

InputPercentFormat.defaultProps = {
  name: '',
};

InputPercentFormat.propTypes = {
  name: PropTypes.string,
  onChange: PropTypes.func.isRequired,
};

const PercentFormat = ({ value }) => (
  <NumericFormat
    value={value}
    displayType="text"
    suffix="%"
  />
);

PercentFormat.propTypes = {
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]).isRequired,
};

const isFsLoaded = (fs) => !Object.values(fs.status.requesting)
  .find((isRequesting) => isRequesting);

const currencyFormatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
});

const isBetaFlagEnabled = () => window.location.host.includes('beta');

const accountBalancesToChartData = (brokerAccountBalances) => {
  let chartData = [];
  if (brokerAccountBalances && Object.keys(brokerAccountBalances).length) {
    chartData = [...brokerAccountBalances.positions, {
      price: brokerAccountBalances.balance || 0,
      symbol: 'Balance',
    }];
  }
  return chartData.sort(({ price: a }, { price: b }) => b - a);
};

const fixNb = (nb) => Number(nb.toFixed(2));

const getSecuritySymbol = (stock) => (typeof stock === 'string' ? stock : stock.symbol);

const useCurrentSecurities = (autoStrategy, accountBalance) => {
  const [currentSecuritiesTotal, setCurrentSecuritiesTotal] = useState(0);
  const [currentSecuritiesPrices, setCurrentSecuritiesPrices] = useState({});
  useEffect(() => {
    let pricesTotal = 0;
    const securitiesPrices = {};
    autoStrategy.forEach(({ stock }) => {
      const security = getSecuritySymbol(stock);
      if (accountBalance && accountBalance.positions) {
        const foundStock = accountBalance.positions.find(({ symbol }) => symbol === security);
        if (foundStock) {
          const { price } = foundStock;
          securitiesPrices[security] = price;
          pricesTotal += price;
        } else {
          securitiesPrices[security] = 0;
        }
      }
    });
    setCurrentSecuritiesPrices(securitiesPrices);
    setCurrentSecuritiesTotal(pricesTotal);
  }, [autoStrategy, accountBalance]);
  return { currentSecuritiesTotal, currentSecuritiesPrices };
};

const accountBalancesProps = PropTypes.shape({
  balance: PropTypes.number,
  positions: PropTypes.arrayOf(PropTypes.shape({
    price: PropTypes.number,
    symbol: PropTypes.string,
  })),
});

const useInput = function useInput(initialValue) {
  const [value, setValue] = useState(initialValue);

  return {
    value,
    setValue,
    reset: () => setValue(''),
    bind: {
      value,
      onChange: (event) => {
        setValue(event.target.value);
      },
    },
  };
};

const capitalize = (str) => str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();

export {
  InputMoneyFormat, MoneyFormat, InputPercentFormat, PercentFormat, fixNb,
  isFsLoaded, currencyFormatter, isBetaFlagEnabled, accountBalancesToChartData,
  getSecuritySymbol, useCurrentSecurities, accountBalancesProps, useInput,
  capitalize,
};
