import React from 'react';
import PropTypes from 'prop-types';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Divider } from '@mui/material';
import {
  MoneyFormat, PercentFormat, fixNb, getSecuritySymbol,
} from '../Utils/Utils';
import { ORDER_TYPES } from '../constants';

const StockOrdersList = ({
  autoStrategy, rebalancing, receipt,
  hiddenShadow, currentSecuritiesPrices, currentSecuritiesTotal,
}) => {
  const isCurrentEnabled = rebalancing
  && currentSecuritiesTotal && Object.keys(currentSecuritiesPrices).length > 0;

  const getFormatedAmount = (orderType, amount, isRebalancing, id) => {
    const receiptAmount = id && receipt?.[id]?.value;
    let formatedAmount = <MoneyFormat value={receiptAmount || amount} />;
    if (orderType === ORDER_TYPES.NB_MARKET_BUY) {
      if (receiptAmount) {
        formatedAmount = isRebalancing ? amount : `${amount} shares ${receiptAmount}$`;
      } else {
        formatedAmount = isRebalancing ? amount : `${amount} shares`;
      }
    } else if (orderType === ORDER_TYPES.PERCENT_MARKET_BUY) {
      formatedAmount = <PercentFormat value={amount} />;
    }
    return formatedAmount;
  };

  return (
    <>
      <Divider style={{ width: '100%' }} />
      <TableContainer
        elevation={1}
        component={hiddenShadow ? 'div' : Paper}
        style={{ maxWidth: '800px', overflowX: 'initial' }}
      >
        <Table size="small" aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Security</TableCell>
              <TableCell align="right" width="15%">{rebalancing ? 'Target' : 'Amount'}</TableCell>
              { isCurrentEnabled ? <TableCell align="right" width="15%">Current</TableCell> : null }
            </TableRow>
          </TableHead>
          <TableBody>
            {!autoStrategy.length ? (
              <TableRow>
                <TableCell />
                <TableCell align="right" />
                { isCurrentEnabled ? <TableCell align="right" /> : null }
              </TableRow>
            ) : null }
            {autoStrategy.map((row) => (
              <TableRow
                key={getSecuritySymbol(row.stock) + row.amount + row.orderType}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <TableCell component="th" scope="row">
                  {getSecuritySymbol(row.stock)}
                </TableCell>
                <TableCell align="right">
                  {getFormatedAmount(row.orderType, row.amount, rebalancing, row?.id)}
                </TableCell>
                { isCurrentEnabled ? (
                  <TableCell align="right">
                    {`${currentSecuritiesTotal === 0 ? 0
                      : fixNb((currentSecuritiesPrices[getSecuritySymbol(row.stock)] / currentSecuritiesTotal) * 100)}%`}
                  </TableCell>
                ) : null}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Divider style={{ width: '100%' }} />
    </>
  );
};

StockOrdersList.defaultProps = {
  hiddenShadow: false,
  rebalancing: false,
  currentSecuritiesPrices: {},
  currentSecuritiesTotal: false,
  receipt: {},
};

StockOrdersList.propTypes = {
  autoStrategy: PropTypes.arrayOf(PropTypes.shape({
    amount: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
    orderType: PropTypes.number.isRequired,
    stock: PropTypes.oneOfType([PropTypes.object, PropTypes.string]).isRequired,
  })).isRequired,
  currentSecuritiesPrices: PropTypes.objectOf(PropTypes.number),
  currentSecuritiesTotal: PropTypes.oneOfType([PropTypes.number, PropTypes.bool]),
  hiddenShadow: PropTypes.bool,
  rebalancing: PropTypes.bool,
  receipt: PropTypes.objectOf(PropTypes.shape({
    value: PropTypes.number,
    quantity: PropTypes.number,
  })),
};

export default StockOrdersList;
