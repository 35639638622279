import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import AddIcon from '@mui/icons-material/Add';
import { Link } from 'react-router-dom';
import Fab from '@mui/material/Fab';
import {
  addDoc,
  collection,
  deleteDoc, doc, getDoc, setDoc,
} from 'firebase/firestore';
import { useSelector, connect, useDispatch } from 'react-redux';
import { useFirestoreConnect } from 'react-redux-firebase';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import WarningIcon from '@mui/icons-material/Warning';
import styled from 'styled-components';
import { Skeleton, Typography, useTheme } from '@mui/material';
import { getAnalytics, logEvent } from 'firebase/analytics';
import Account from './Account/Account';
import {
  selectAuth, submitOrder, setSnackbar, selectBrokerAccounts, selectBrokersBalances,
} from '../AutoInvestSlice';
import { BOTTOM_NAVIGATION_HEIGHT, useMobileDevice } from '../../Common/responsiveUIHelper';
import AccountsSplashScreen from './AccountsSplashScreen';
import { capitalize, isFsLoaded } from '../Utils/Utils';
import { validateSecurities } from '../AutoInvestAPI';
import { QUESTRADE, WEALTHSIMPLE } from '../constants';

const analytics = getAnalytics();

const AccountsWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  height: ${({ isMobileDevice }) => (isMobileDevice ? `calc(100% - ${BOTTOM_NAVIGATION_HEIGHT})` : '100%')};
  width: 100%;
  overflow-y: auto;
  background-color: ${(props) => props.theme.palette.background.default};
`;
const AccountWrapper = styled.div`
  max-width: 1000px;
  width: 100%;
  padding-bottom: ${({ isMobileDevice }) => (isMobileDevice ? '120px' : '24px')};
  `;

const BrokerLoginWarning = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 18px;
  margin-left: 12px;
  margin-right: 12px;
`;

const Accounts = ({ db, auth }) => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const authSelector = useSelector(selectAuth);
  const isMobileDevice = useMobileDevice();
  const brokersBalances = useSelector(selectBrokersBalances);
  const QTAccounts = useSelector(selectBrokerAccounts(QUESTRADE));
  useFirestoreConnect([
    {
      collection: 'strategies',
      where: [['uid', '==', auth.uid]],
      orderBy: ['created', 'desc'],
    },
  ]);

  const strategiesFirestore = useSelector((state) => state.firestore);
  const investingStrategies = strategiesFirestore.ordered.strategies;
  const [openTestStratDialog, setOpenTestStratDialog] = useState(false);
  const [openDeleteStratDialog, setOpenDeleteStratDialog] = useState(false);
  const [selectedStrategy, setSelectedStrategy] = useState('');
  const [selectedBroker, setSelectedBroker] = useState('');
  const [isTestStratLoading, setIsTestStratLoading] = useState(false);
  const [isStrategyActivating, setIsStrategyActivating] = useState(false);
  const [groupedStrats, setGroupedStrats] = useState({});

  useEffect(() => {
    if (investingStrategies?.length > 0 || Object.keys(brokersBalances) > 0) {
      const StratsAccountGroup = {};
      investingStrategies?.forEach((element) => {
        const { broker, account, id } = element;
        const accountName = broker === QUESTRADE
          ? `${QTAccounts[account]?.type} ${account.toUpperCase()}` || account.toUpperCase()
          : account.toUpperCase();

        const groupId = `${capitalize(broker)} ${accountName}`;
        StratsAccountGroup[groupId] = StratsAccountGroup[groupId] || {};

        StratsAccountGroup[groupId].details = { broker, account, id };
        StratsAccountGroup[groupId].strategies = StratsAccountGroup[groupId].strategies || {};
        StratsAccountGroup[groupId].strategies[id] = element;
      });

      Object.entries(brokersBalances).forEach(([broker, brokerAccount]) => {
        Object.keys(brokerAccount).forEach((account) => {
          const accountName = broker === QUESTRADE
            ? `${QTAccounts[account]?.type} ${account.toUpperCase()}` || account.toUpperCase()
            : account.toUpperCase();
          const groupId = `${capitalize(broker)} ${accountName}`;
          if (!StratsAccountGroup[groupId]
            && !(broker === WEALTHSIMPLE && account === 'crypto')) {
            StratsAccountGroup[groupId] = {};
            StratsAccountGroup[groupId].details = { broker, account };
          }
        });
      });
      setGroupedStrats(StratsAccountGroup);
    }
  }, [investingStrategies, brokersBalances]);

  const handleTestStratDialog = (id, broker) => {
    setSelectedBroker(broker);
    setOpenTestStratDialog(true);
    setSelectedStrategy(id);
  };

  const handleCloseTestStratDialog = () => {
    setOpenTestStratDialog(false);
    setSelectedStrategy('');
  };

  const handleDeleteStratDialog = (id) => {
    setOpenDeleteStratDialog(true);
    setSelectedStrategy(id);
  };

  const handleCloseDeleteStratDialog = () => {
    setOpenDeleteStratDialog(false);
    setSelectedStrategy('');
  };

  const copyStrategy = async (id) => {
    try {
      const docSnap = await getDoc(doc(db, 'strategies', id));
      if (docSnap.exists()) {
        const strat = docSnap.data();
        await addDoc(collection(db, 'strategies'), { ...strat, nickname: `Copy of ${strat.nickname}` });
      }
    } catch (e) {
      console.error('Error deleting document: ', e);
    }
  };

  const removeStrategy = () => {
    try {
      deleteDoc(doc(db, 'strategies', selectedStrategy));
      handleCloseDeleteStratDialog();
    } catch (e) {
      console.error('Error deleting document: ', e);
    }
  };

  const testStrategy = async () => {
    setIsTestStratLoading(({ [selectedStrategy]: true }));
    logEvent(analytics, 'Manual_Order');
    handleCloseTestStratDialog();
    await dispatch(submitOrder(selectedStrategy));
    setIsTestStratLoading(false);
  };

  const setStrategyActivated = async (evt, strategyId) => {
    try {
      setIsStrategyActivating({ [strategyId]: true });
      evt.preventDefault();
      const activatedNewStatus = evt.target.checked;
      const stratRef = doc(db, 'strategies', strategyId);
      if (activatedNewStatus) {
        const areSecuritiesValid = await validateSecurities(strategyId);
        if (areSecuritiesValid.valid) {
          setDoc(stratRef, { activated: activatedNewStatus }, { merge: true });
        } else {
          dispatch(setSnackbar({
            open: true,
            severity: 'error',
            message: areSecuritiesValid.errorDetails ? `${areSecuritiesValid.errorDetails} - Invalid Security` : areSecuritiesValid.toString().replace('FirebaseError:', ''),
          }));
        }
      } else {
        setDoc(stratRef, { activated: activatedNewStatus }, { merge: true });
      }
      setIsStrategyActivating(false);
    } catch (e) {
      console.error('Error adding document: ', e);
      setIsStrategyActivating(false);
    }
  };

  const LoginStatus = () => {
    let status = null;
    if (authSelector[WEALTHSIMPLE].isLoading || authSelector[QUESTRADE].isLoading) {
      status = (
        <BrokerLoginWarning>
          <Typography variant="h1" component="div">
            <Skeleton style={{ width: '350px', height: '24px' }} />
          </Typography>
        </BrokerLoginWarning>
      );
    } else if (!authSelector[WEALTHSIMPLE].isLogged && !authSelector[QUESTRADE].isLogged) {
      status = (
        <BrokerLoginWarning>
          <WarningIcon color="error" />
          <Typography variant="body1" component="div" style={{ color: theme.palette.primary.main }}>
            You need to link a broker account to activate your strategies
          </Typography>
        </BrokerLoginWarning>
      );
    }
    return status;
  };

  const getSplashScreen = () => (isFsLoaded(strategiesFirestore) ? <AccountsSplashScreen />
    : (
      <>
        <Skeleton
          sx={{ maxWidth: '800px', marginBottom: '24px' }}
          variant="rectangular"
          width="100%"
          height={300}
        />
        <Skeleton
          sx={{ maxWidth: '800px', marginBottom: '24px' }}
          variant="rectangular"
          width="100%"
          height={300}
        />
      </>
    ));

  return (
    <AccountsWrapper theme={theme} isMobileDevice={isMobileDevice}>
      <Typography variant={isMobileDevice ? 'h5' : 'h4'} gutterBottom component="div" style={{ marginTop: isMobileDevice ? 8 : 16, color: theme.palette.primary.main }}>Strategies</Typography>
      {!investingStrategies || (investingStrategies && investingStrategies.length === 0)
        ? getSplashScreen() : (
          <>
            <LoginStatus />
            <Fab
              variant="extended"
              color="primary"
              aria-label="add"
              size={isMobileDevice ? 'medium' : 'large'}
              component={Link}
              to="/strategies/new"
              sx={isMobileDevice ? {
                mr: 1,
                position: 'absolute',
                bottom: 76,
                right: 8,
                zIndex: 3,
              } : {
                mr: 1,
                position: 'absolute',
                top: 87,
                right: 16,
              }}
            >
              <AddIcon sx={{ mr: 1 }} />
              Add Strategy
            </Fab>
            <AccountWrapper isMobileDevice={isMobileDevice}>
              {Object.entries(groupedStrats).map(([accountKey, { details, strategies }]) => (
                <Account
                  key={accountKey}
                  broker={details.broker}
                  account={details.account}
                  accountKey={accountKey}
                  accountStrategies={strategies}
                  accountBalances={brokersBalances?.[details.broker]?.[details.account]}
                  testStrategy={handleTestStratDialog}
                  copyStrategy={copyStrategy}
                  isTestStratLoading={isTestStratLoading}
                  removeStrategy={handleDeleteStratDialog}
                  setStrategyActivated={setStrategyActivated}
                  isStrategyActivating={isStrategyActivating}
                />
              ))}
            </AccountWrapper>

          </>
        )}
      <Dialog
        // fullScreen={fullScreen}
        open={openTestStratDialog}
        onClose={handleCloseTestStratDialog}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">
          Submit your order to
          {selectedBroker === QUESTRADE ? ' Questrade' : ' Wealthsimple'}
          ?
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            {selectedBroker === QUESTRADE ? 'Questrade ' : 'Wealthsimple '}
            will then create an order.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleCloseTestStratDialog}>
            Cancel
          </Button>
          <Button onClick={testStrategy} autoFocus>
            Submit Order
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        // fullScreen={fullScreen}
        open={openDeleteStratDialog}
        onClose={handleCloseTestStratDialog}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">
          Delete this strategy?
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            This strategy will be deleted
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleCloseDeleteStratDialog}>
            Cancel
          </Button>
          <Button onClick={removeStrategy} autoFocus>
            Delete Strategy
          </Button>
        </DialogActions>
      </Dialog>
    </AccountsWrapper>
  );
};

Accounts.propTypes = {
  db: PropTypes.shape({
    collection: PropTypes.func,
  }).isRequired,
  auth: PropTypes.shape({
    uid: PropTypes.string.isRequired,
  }).isRequired,
};

const enhance = connect(
  // Map redux state to component props
  ({ firebase: { auth } }) => ({
    auth,
  }),
);

export default enhance(Accounts);
