import { Box, useTheme } from '@mui/material';
import React from 'react';
import Container from '../Container';
import Footer from '../Footer/Footer';

const PrivacyPolicy = () => {
  const theme = useTheme();
  return (
    <Box sx={{ background: theme.palette.background.paper, overflowY: 'auto' }}>
      <Box
        sx={{
          marginTop: -13,
          paddingTop: 13,
        }}
      >
        <Container textAlign="left">
          <h1>Privacy Policy</h1>
          <p>Last updated: March 5, 2023</p>
          <p>
            This privacy agreement (“Agreement”) outlines the policies and practices
            governing the collection, use, and disclosure of personal information in
            connection with the use of WealthLucid.com (“Website”) provided by WealthLucid,
            Inc. (“we” or “us”). By using the Website, you acknowledge and accept
            the terms and conditions of this Agreement.
          </p>
          <h2>Collection of Personal Information</h2>
          <p>
            When you use the Website, we may collect personal information such as your name,
            and investment preferences. We may also collect information
            about your use of the Website, including the frequency and duration of your use,
            and the securities that you have invested in.
          </p>
          <h2>Security of Personal Information</h2>
          <p>
            We take reasonable steps to protect your personal information from unauthorized
            access, use, and disclosure. We use industry-standard security measures
            to safeguard your personal information.
          </p>
          <h2>Access to Personal Information</h2>
          <p>
            You may request access to, correction of, or deletion of your personal
            information by contacting us at contact@wealthlucid.com. We will respond
            to your request within a reasonable timeframe and in accordance
            with applicable legal requirements.
          </p>
          <h2>Cookies and Similar Technologies</h2>
          <p>
            We may use cookies and similar technologies to enhance your experience on
            the Website and to collect information about your use of the Website.
          </p>
          <h2>Contact Us</h2>
          <p>
            If you have any questions or concerns about this Agreement or
            our privacy practices, please contact us:
          </p>
          <ul>
            <li>By email: contact@wealthlucid.com</li>
          </ul>
        </Container>
      </Box>
      <Box bgcolor={theme.palette.background.paper}>
        <Container style={{ paddingBottom: 16 }}>
          <Footer />
        </Container>
      </Box>
    </Box>
  );
};

export default PrivacyPolicy;
